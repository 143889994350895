(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('FixturesDeleteController',FixturesDeleteController);

    FixturesDeleteController.$inject = ['$uibModalInstance', 'entity', 'Fixtures'];

    function FixturesDeleteController($uibModalInstance, entity, Fixtures) {
        var vm = this;

        vm.fixtures = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Fixtures.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
