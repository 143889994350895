(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('fixtures', {
            parent: 'cmp',
            url: '/fixtures?page&sort&search',
            data: {
                pageTitle: 'Fixtures'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/fixtures/fixtures.html',
                    controller: 'FixturesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'startDate,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('fixtures-detail', {
            parent: 'fixtures',
            url: '/{id}',
            data: {
                pageTitle: 'Fixtures'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/fixtures/fixtures-detail.html',
                    controller: 'FixturesDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Fixtures', function($stateParams, Fixtures) {
                    return Fixtures.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'fixtures',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
            .state('fixtures-scoring', {
                parent: 'fixtures',
                url: '/{id}/scoring',
                data: {
                    pageTitle: 'Fixtures'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/competition/fixtures/scoring.html',
                        controller: 'ScoringController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Fixtures', function($stateParams, Fixtures) {
                        return Fixtures.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'fixtures',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
        .state('fixtures-detail.edit', {
            parent: 'fixtures-detail',
            url: '/detail/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/fixtures/fixtures-dialog.html',
                    controller: 'FixturesDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Fixtures','$stateParams', function(Fixtures,$stateParams) {
                    return Fixtures.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('fixtures.new', {
            parent: 'fixtures',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/fixtures/fixtures-dialog.html',
                    controller: 'FixturesDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        matchNo: null,
                        startDate: null,
                        endDate: null,
                        matchVs: null,
                        matchStatus: null,
                        createdBy: null,
                        createdDate: null,
                        lastModifiedBy: null,
                        lastModifiedDate: null,
                        id: null,
                        playerOfTheMatch: null,
                        leagueMatch:true
                    };
                }
            }

        })
        .state('fixtures.edit', {
            parent: 'fixtures',
            url: '/{id}/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/fixtures/fixtures-dialog.html',
                    controller: 'FixturesDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Fixtures','$stateParams', function(Fixtures,$stateParams) {
                    return Fixtures.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('fixtures.delete', {
            parent: 'fixtures',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/competition/fixtures/fixtures-delete-dialog.html',
                    controller: 'FixturesDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Fixtures', function(Fixtures) {
                            return Fixtures.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('fixtures', null, { reload: 'fixtures' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
