(function() {
    'use strict';

    angular
        .module('techdirectiveApp', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngSanitize',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'oc.lazyLoad',
            'ui.select',
            'datatables',
            'LocalStorageModule',
            'summernote',
            'pascalprecht.translate',
            'autoCompleteModule',
            'ngMeta'
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            //'angular-loading-bar'
        ])
        .run(run)
       /* .config(['$httpProvider', function ($httpProvider) {
            $httpProvider.defaults.withCredentials = true;
            $httpProvider.interceptors.push('XSRFInterceptor');

        }]) */
    ;

    run.$inject = ['stateHandler','ngMeta'];

    function run(stateHandler, ngMeta) {
        stateHandler.initialize();
        ngMeta.init();
    }
})();
