(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('DatesDeleteController',DatesDeleteController);

    DatesDeleteController.$inject = ['$uibModalInstance', 'entity', 'Dates'];

    function DatesDeleteController($uibModalInstance, entity, Dates) {
        var vm = this;

        vm.dates = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Dates.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
