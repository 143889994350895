(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('BlogDialogController', BlogDialogController);

    BlogDialogController.$inject = ['$timeout', '$scope', '$state', '$http', '$stateParams', 'entity', 'Blog'];

    function BlogDialogController ($timeout, $scope, $state, $http, $stateParams, entity, Blog) {
        var vm = this;

        vm.blog = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        $scope.myFile = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('blog', null, { reload: 'blog' });
        }

        // save blog
        $scope.saveBlog = function(file) {

            var saveBlogUrl = 'api/blogs/save';
            var fd = new FormData();
            if (count_obj(file)) {
                fd.append('file', file);
            }
            fd.append('blog',angular.toJson(vm.blog));
            $http.post(saveBlogUrl, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
                .success( function (data) {
                        console.log('success');
                        onSaveSuccess(data);
                    }
                )
                .error( function () {
                    onSaveError();
                    console.log('error');
                });
        };

        function save () {
            vm.isSaving = true;
            if ($scope.myFile) {
                $scope.saveBlog($scope.myFile);
            }
           /* if (vm.blog.id !== null) {
                Blog.update(vm.blog, onSaveSuccess, onSaveError);
            } else {
                Blog.save(vm.blog, onSaveSuccess, onSaveError);
            } */
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:blogUpdate', result);
            $state.go('blog', null, { reload: 'blog' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdOn = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function count_obj(obj){
            var i = 0;
            for(var key in obj){
                ++i;
            }
            return i;
        }

        $scope.options = {
            height: 200,
            focus: true,
            airMode: false,
            toolbar: [
                ['edit',['undo','redo']],
                ['headline', ['style']],
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
                ['fontface', ['fontname']],
                ['textsize', ['fontsize']],
                ['fontclr', ['color']],
                ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
                ['height', ['height']],
                ['table', ['table']],
                ['insert', ['link','picture','video','hr']],
                ['view', ['fullscreen', 'codeview']],
                ['help', ['help']]
            ]
        };

    }
})();
