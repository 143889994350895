(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('commentary-item', {
            parent: 'entity',
            url: '/commentary-item',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'CommentaryItems'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/commentary-item/commentary-items.html',
                    controller: 'CommentaryItemController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('commentary-item-detail', {
            parent: 'commentary-item',
            url: '/commentary-item/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'CommentaryItem'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/commentary-item/commentary-item-detail.html',
                    controller: 'CommentaryItemDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CommentaryItem', function($stateParams, CommentaryItem) {
                    return CommentaryItem.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'commentary-item',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('commentary-item-detail.edit', {
            parent: 'commentary-item-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commentary-item/commentary-item-dialog.html',
                    controller: 'CommentaryItemDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CommentaryItem', function(CommentaryItem) {
                            return CommentaryItem.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('commentary-item.new', {
            parent: 'commentary-item',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commentary-item/commentary-item-dialog.html',
                    controller: 'CommentaryItemDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                fixtureId: null,
                                inning: null,
                                teamId: null,
                                bowlerName: null,
                                batsmanName: null,
                                runoutBy: null,
                                coughtBy: null,
                                stumpedBy: null,
                                oversBowled: null,
                                runsScored: null,
                                wide: null,
                                noball: null,
                                bye: null,
                                legbye: null,
                                wicket: null,
                                shortText: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('commentary-item', null, { reload: 'commentary-item' });
                }, function() {
                    $state.go('commentary-item');
                });
            }]
        })
        .state('commentary-item.edit', {
            parent: 'commentary-item',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commentary-item/commentary-item-dialog.html',
                    controller: 'CommentaryItemDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CommentaryItem', function(CommentaryItem) {
                            return CommentaryItem.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('commentary-item', null, { reload: 'commentary-item' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('commentary-item.delete', {
            parent: 'commentary-item',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commentary-item/commentary-item-delete-dialog.html',
                    controller: 'CommentaryItemDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CommentaryItem', function(CommentaryItem) {
                            return CommentaryItem.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('commentary-item', null, { reload: 'commentary-item' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
