(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('TeamStatsDialogController', TeamStatsDialogController);

    TeamStatsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TeamStats', 'Fixtures', 'Teams'];

    function TeamStatsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TeamStats, Fixtures, Teams) {
        var vm = this;

        vm.teamStats = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.fixtures = Fixtures.query();
        vm.teams = Teams.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.teamStats.id !== null) {
                TeamStats.update(vm.teamStats, onSaveSuccess, onSaveError);
            } else {
                TeamStats.save(vm.teamStats, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:teamStatsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
