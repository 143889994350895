(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('PlayersController', PlayersController);

    PlayersController.$inject = ['$scope', '$state', 'Players', 'PlayersSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Teams'];

    function PlayersController ($scope, $state, Players, PlayersSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Teams) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.setActive = setActive;
        $scope.searchByTeam = searchByTeam;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search!==null?pagingParams.search.replace(/"/g,""):null;
        vm.currentSearch = pagingParams.search;
        vm.searchByTeam = false;
        vm.teams = Teams.query();

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                PlayersSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Players.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }


        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.players = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function searchByTeam(searchByTeam, searchQuery) {
            if (searchByTeam) {
                var qu = '"' + searchQuery + '"';
                search (qu);
            }
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'name';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function setActive (player, isActivated) {
            player.active = isActivated;
            Players.update(player, function () {
                vm.loadAll();
            });
        }

        $scope.items = vm.itemsPerPage;
        $scope.scrollPage = function () {
            $scope.scrollDisabled = false;
            if (vm.players.length <= $scope.items - vm.itemsPerPage) {
                $scope.scrollDisabled = true;
            }
            if (!$scope.scrollDisabled) {
                $scope.items = $scope.items + vm.itemsPerPage;
                if (pagingParams.search) {
                    PlayersSearch.query({
                        query: pagingParams.search,
                        page: vm.page - 1,
                        size: $scope.items,
                        sort: sort()
                    }, onSuccess, onError);
                } else {
                    Players.query({
                        page: vm.page - 1,
                        size: $scope.items,
                        sort: sort()
                    }, onSuccess, onError);
                }
            }

        }
    }
})();
