(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', '$scope','$state','$http','User', 'Sessions','Principal', 'Auth','DateUtils'];

    function UserManagementDetailController ($stateParams, $scope,$state, $http, User,Sessions,Principal, Auth,DateUtils) {
        var vm = this;

        vm.load = load;
        vm.user = {};
        vm.sessions = Sessions.getAll();
        vm.loadUserSettings = loadUserSettings;
        vm.clear = clear;
        vm.saveUser = saveUser;

        vm.load($stateParams.login);
        vm.loadUserSettings();
        function load (login) {
            User.get({login: login}, function(result) {
                vm.user = result;
            });
        }



        // begin personal info //
        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                photo: account.photo,
                phone: account.phone,
                dateOfBirth:DateUtils.convertDateTimeFromServer(account.dateOfBirth),
                addressLine1:account.addressLine1,
                addressLine2:account.addressLine2,
                city:account.city,
                state:account.state,
                pinCode:account.pinCode,
                country:account.country
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
        });

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                });
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        // end of personal info //

        // begin password change //
        vm.changePassword = changePassword;
        vm.doNotMatch = null;

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
        // end password change //

        // begin sessions //
        vm.invalidate = invalidate;
        function invalidate (series) {
            Sessions.delete({series: encodeURIComponent(series)},
                function () {
                    vm.error = null;
                    vm.success = 'OK';
                    vm.sessions = Sessions.getAll();
                },
                function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
        }
        // end sessions

        // begin upload user image //
        vm.uploadImg = uploadImg;
        $scope.myFile = {};
        // upload on file select or drop
        function uploadImg() {
            if ($scope.myFile) {
                var uploadUrl = 'api/users/photo';
                var fd = new FormData();
                if (count_obj($scope.myFile)) {
                    fd.append('photo', $scope.myFile);
                }
                fd.append("login", vm.settingsAccount.login);
                $http.post(uploadUrl, fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                })
                    .success( function (data) {
                            vm.settingsAccount = copyAccount(data);
                            vm.error = null;
                            vm.success = 'OK';
                        }
                    )
                    .error( function () {
                        vm.success = null;
                        vm.error = 'ERROR';
                    });
            }

        }

        function count_obj(obj){
            var i = 0;
            for(var key in obj){
                ++i;
            }
            return i;
        }
        // end upload user image //
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.dateOfBirth = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        // submit user settings //
        vm.userSettings = {};
        vm.submit = submit;
        vm.loadUserSettings = loadUserSettings;

        function submit () {
            vm.isSaving = true;

            var userSettingsUrl = 'api/user-settings';
            $http.put(userSettingsUrl, vm.userSettings)
                .success( function (data) {
                    vm.userSettings = data;
                    }
                )
                .error( function () {
                });

        }

        // load user settings to set radio buttons
        function loadUserSettings () {
            var userSettingsUrl = 'api/user-settings';
            $http.get(userSettingsUrl)
                .success( function (data) {
                        vm.userSettings = data;
                    }
                )
                .error( function () {
                });

        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:userSettingsUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        function clear () {
            $state.go('user-management-detail', null, { reload: true });
        }

        function onSaveUserSuccess (result) {
            vm.isSaving = false;
            $state.go('user-management-detail', null, { reload: true });
        }

        function onSaveUserError () {
            vm.isSaving = false;
        }

        function saveUser () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveUserSuccess, onSaveUserError);
            } else {
                vm.user.langKey = 'en';
                User.save(vm.user, onSaveUserSuccess, onSaveUserError);
            }
        }
    }
})();
