(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('avmanagement', {
            parent: 'member',
            url: '/users/availability',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Availability Management'
            },
            views: {
                'content@': {
                    templateUrl: 'app/member/avmanagement/avmanagement.html',
                    controller: 'AvmanagementController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

            }
        });

    }

})();
