(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('PhotosDetailController', PhotosDetailController);

    PhotosDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Photos', 'Album'];

    function PhotosDetailController($scope, $rootScope, $stateParams, previousState, entity, Photos, Album) {
        var vm = this;

        vm.photos = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('techdirectiveApp:photosUpdate', function(event, result) {
            vm.photos = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
