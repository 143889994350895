(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('PointsTableDialogController', PointsTableDialogController);

    PointsTableDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'PointsTable', 'Teams', 'Tournament','Groups'];

    function PointsTableDialogController ($timeout, $scope, $stateParams, $state, entity, PointsTable, Teams,Tournament,Groups) {
        var vm = this;

        vm.pointsTable = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.teams = Teams.query();
        vm.tournaments = Tournament.query();
        vm.groups = Groups.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('points-table', null, { reload: 'points-table' });
        }

        function save () {
            vm.isSaving = true;
            if (vm.pointsTable.id !== null) {
                PointsTable.update(vm.pointsTable, onSaveSuccess, onSaveError);
            } else {
                PointsTable.save(vm.pointsTable, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:pointsTableUpdate', result);
            $state.go('points-table', null, { reload: 'points-table' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
