(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .factory('FixturesSearch', FixturesSearch);

    FixturesSearch.$inject = ['$resource'];

    function FixturesSearch($resource) {
        var resourceUrl =  'api/_search/fixtures/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,
                params: {tournamentId: null}}
        });
    }
})();
