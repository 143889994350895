(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('StatisticsDialogController', StatisticsDialogController);

    StatisticsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', '$q', 'entity', 'Statistics', 'User', 'Calendar'];

    function StatisticsDialogController ($timeout, $scope, $stateParams, $state, $q, entity, Statistics, User, Calendar) {
        var vm = this;

        vm.statistics = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();

        vm.calendars = Calendar.query({filter: 'statistics-is-null'});
        $q.all([vm.statistics.$promise, vm.calendars.$promise]).then(function() {
            if (!vm.statistics.calendar || !vm.statistics.calendar.id) {
                return $q.reject();
            }
            return Calendar.get({id : vm.statistics.calendar.id}).$promise;
        }).then(function(calendar) {
            vm.calendars.push(calendar);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('statistics', null, { reload: 'statistics' });
        }

        function save () {
            vm.isSaving = true;
            if (vm.statistics.id !== null) {
                Statistics.update(vm.statistics, onSaveSuccess, onSaveError);
            } else {
                Statistics.save(vm.statistics, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:statisticsUpdate', result);
            $state.go('statistics', null, { reload: 'statistics' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
