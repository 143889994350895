(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .directive('backImg', ['$parse', function($parse) {
            return function(scope, element, attrs){

                var url = attrs.backImg;
                element.css({
                    'background-image': 'url(' + url +')',
                    'background-size' : 'cover'
                });

            };
        }])
})();
