(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tournament', {
            parent: 'cmp',
            url: '/tournament?page&sort&search',
            data: {
                pageTitle: 'Tournaments'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/tournament/tournaments.html',
                    controller: 'TournamentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'createdDate,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('tournament-detail', {
            parent: 'tournament',
            url: '/{id}',
            data: {
                pageTitle: 'Tournament'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/tournament/tournament-detail.html',
                    controller: 'TournamentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Tournament', function($stateParams, Tournament) {
                    return Tournament.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tournament',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tournament-detail.edit', {
            parent: 'tournament-detail',
            url: '/detail/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/tournament/tournament-dialog.html',
                    controller: 'TournamentDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Tournament', '$stateParams', function(Tournament,$stateParams) {
                    return Tournament.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('tournament.new', {
            parent: 'tournament',
            url: '/new',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/tournament/tournament-dialog.html',
                    controller: 'TournamentDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        id: null,
                        name: null,
                        createdBy: null,
                        active: null,
                        createdDate: null,
                        lastModifiedBy: null,
                        lastModifiedDate: null,
                        place: null,
                        startDate: null,
                        endDate: null,
                        image: null
                    };
                }
            }
        })
        .state('tournament.edit', {
            parent: 'tournament',
            url: '/{id}/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/tournament/tournament-dialog.html',
                    controller: 'TournamentDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Tournament','$stateParams', function(Tournament,$stateParams) {
                    return Tournament.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('tournament.delete', {
            parent: 'tournament',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/competition/tournament/tournament-delete-dialog.html',
                    controller: 'TournamentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Tournament', function(Tournament) {
                            return Tournament.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tournament', null, { reload: 'tournament' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
