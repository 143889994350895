(function() {
    'use strict';
    angular
        .module('techdirectiveApp')
        .factory('WeatherIcons', WeatherIcons);

    WeatherIcons.$inject = [];

    function WeatherIcons () {
        // * All the codes by are based in the openweather API
        //  http://openweathermap.org/wiki/API/Weather_Condition_Codes
        // * Icon based:
        //  in http://erikflowers.github.io/weather-icons/cheatsheet/index.html
        return {
            '200': 'wi-storm-showers ',
            '201': 'wi-storm-showers ',
            '202': 'wi-storm-showers ',
            '210': 'wi-thunderstorm ',
            '211': 'wi-thunderstorm ',
            '212': 'wi-thunderstorm ',
            '221': 'wi-lightning',
            '230': 'wi-storm-showers',
            '231': 'wi-storm-showers',
            '232': 'wi-storm-showers',
            '300': 'wi-sprinkle',
            '301': 'wi-sprinkle',
            '302': 'wi-rain-mix',
            '310': 'wi-rain-mix',
            '311': 'wi-rain',
            '312': 'wi-rain',
            '321': 'wi-rain-wind',
            '500': 'wi-sprinkle',
            '501': 'wi-showers',
            '502': 'wi-rain-mix',
            '503': 'wi-rain',
            '504': 'wi-rain-wind',
            '511': 'wi-snow',
            '520': 'wi-rain-mix',
            '521': 'wi-rain',
            '522': 'wi-rain-wind',
            '600': 'wi-snow',
            '601': 'wi-snow',
            '602': 'wi-snow',
            '611': 'wi-hail',
            '620': 'wi-snow',
            '621': 'wi-snow',
            '701': 'wi-fog',
            '711': 'wi-fog',
            '721': 'wi-fog',
            '731': 'wi-fog',
            '741': 'wi-fog',
            '01d': 'wi-day-sunny',
            '01n': 'wi-night-clear',
            '02d': 'wi-day-cloudy',
            '02n': 'wi-night-cloudy',
            '03d': 'wi-cloudy',
            '04n': 'wi-night-partly-cloudy',
            '04d': 'wi-cloudy',
            '900': 'wi-tornado',
            '901': 'wi-thunderstorm',
            '902': 'wi-lightning',
            '903': 'wi-thermometer-exterior',
            '904': 'wi-thermometer',
            '905': 'wi-strong-wind',
            '906': 'wi-hail'
        };
    }

})();
