(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$ocLazyLoadProvider'];

    function stateConfig($stateProvider, $ocLazyLoadProvider) {
        $stateProvider
        .state('photos', {
            parent: 'album',
            url: '/photos',
            data: {
                pageTitle: 'Photos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/gallery/photos/photos.html',
                    controller: 'PhotosController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                deps: ['$ocLazyLoad', function($ocLazyLoad) {
                    return $ocLazyLoad.load({
                        name: 'techdirectiveApp'
                    });
                }]
            }
        })
        .state('photos-detail', {
            parent: 'app',
            url: '/photos/{id}',
            data: {
                pageTitle: 'Photos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/gallery/photos/photos-detail.html',
                    controller: 'PhotosDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Photos', function($stateParams, Photos) {
                    return Photos.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'photos',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('photos-detail.edit', {
            parent: 'photos-detail',
            url: '/detail/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/gallery/photos/photos-dialog.html',
                    controller: 'PhotosDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Photos','$stateParams', function(Photos,$stateParams) {
                    return Photos.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('photos.new', {
            parent: 'photos',
            url: '/new',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/gallery/photos/photos-dialog.html',
                    controller: 'PhotosDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        title: null,
                        photoUrl: null,
                        createdBy: null,
                        createdDate: null,
                        id: null
                    };
                }
            }

        })
        .state('photos.edit', {
            parent: 'photos',
            url: '/{id}/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/gallery/photos/photos-dialog.html',
                    controller: 'PhotosDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Photos','$stateParams', function(Photos,$stateParams) {
                    return Photos.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('photos.delete', {
            parent: 'photos',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/gallery/photos/photos-delete-dialog.html',
                    controller: 'PhotosDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Photos', function(Photos) {
                            return Photos.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('photos', null, { reload: 'photos' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
