(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('ClubteamController', ClubteamController);

    ClubteamController.$inject = ['$scope', '$http', 'LoginService', '$state'];

    function ClubteamController ($scope, $http, LoginService, $state) {
        var vm = this;
        vm.load = load;
        vm.teams = [];
        load();
        function load() {
            var contactUrl = 'api/teams/clubteam';
            $http.get(contactUrl)
                .success( function (data) {
                  vm.teams = data;
                    }
                )

        }
    }

})();
