(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .directive('subMenu', ['$parse', function($parse) {
            return {
                restrict: "E",
                template: "<div class=\"tab-pane\">\n" +
                "                <div class=\"tabbable-line\">\n" +
                "                    <ul class=\"nav nav-tabs\">\n" +
                "                        <li ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
                "                            <a ui-sref=\"tournament\" data-target=\"#tab_1_0\" data-toggle=\"tab\">Trophies & Games</a>\n" +
                "                        </li>\n" +
                "                        <li has-authority=\"ROLE_ADMIN\" ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
                "                            <a ui-sref=\"teams\" class=\"nav-link nav-toggle\" data-target=\"#tab_1_1\" data-toggle=\"tab\"> Teams </a>\n" +
                "                        </li>\n" +
                "\n" +
                "                        <li has-authority=\"ROLE_ADMIN\" ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
                "                            <a ui-sref=\"players\" class=\"nav-link nav-toggle\" data-target=\"#tab_1_2\" data-toggle=\"tab\"> Players </a>\n" +
                "                        </li>\n" +
                "\n" +
                "                        <li has-authority=\"ROLE_ADMIN\" ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
                "                            <a ui-sref=\"fixtures\" class=\"nav-link nav-toggle\" data-target=\"#tab_1_3\" data-toggle=\"tab\"> Fixtures </a>\n" +
                "                        </li>\n" +
                "\n" +
                "                        <li has-authority=\"ROLE_ADMIN\" ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
                "                            <a ui-sref=\"groups\" class=\"nav-link nav-toggle\" data-target=\"#tab_1_4\" data-toggle=\"tab\"> Groups </a>\n" +
                "                        </li>\n" +
                "\n" +
                "                        <li has-authority=\"ROLE_ADMIN\" ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
                "                            <a ui-sref=\"points-table\" class=\"nav-link nav-toggle\" data-target=\"#tab_session_view\" data-toggle=\"tab\"> Points Table </a>\n" +
                "                        </li>\n" +
                "\n" +
                "                    </ul>\n" +
                "                </div>\n" +
                "            </div>"
            };
        }])
})();
