(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('PhotosDialogController', PhotosDialogController);

    PhotosDialogController.$inject = ['$timeout', '$scope', '$http', '$state', '$stateParams', 'entity', 'Photos', 'Album'];

    function PhotosDialogController ($timeout, $scope, $http, $state, $stateParams, entity, Photos, Album) {
        var vm = this;

        vm.photos = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.albums = Album.query();
        $scope.myFile = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('photos', null, { reload: 'photos' });
        }

        // upload on file select or drop
        $scope.uploadPic = function(file) {

            var uploadUrl = 'api/photos/upload';
            var fd = new FormData();
            if (count_obj(file)) {
                fd.append('file', file);
            }
            fd.append('photos',angular.toJson(vm.photos));
            $http.post(uploadUrl, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
            .success( function (data) {
                    console.log('success');
                    onSaveSuccess(data);
                }
            )
            .error( function () {
                onSaveError();
                console.log('error');
            });
        };

        function save () {
            vm.isSaving = true;
            if ($scope.myFile) {
                $scope.uploadPic($scope.myFile);
            }


            /* if (vm.photos.id !== null) {
                 Photos.update(vm.photos, onSaveSuccess, onSaveError);
             } else {
                 Photos.save(vm.photos, onSaveSuccess, onSaveError);
             }*/
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:photosUpdate', result);
            $state.go('photos', null, { reload: 'photos' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function count_obj(obj){
            var i = 0;
            for(var key in obj){
                ++i;
            }
            return i;
        }
    }
})();
