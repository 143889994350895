(function() {
    'use strict';
    angular
        .module('techdirectiveApp')
        .factory('CommentaryItem', CommentaryItem);

    CommentaryItem.$inject = ['$resource'];

    function CommentaryItem ($resource) {
        var resourceUrl =  'api/commentary-items/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
