(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('TeamsDialogController', TeamsDialogController);

    TeamsDialogController.$inject = ['$timeout', '$scope', '$state', '$http', '$stateParams', 'entity', 'Teams', 'Groups', 'Players'];

    function TeamsDialogController ($timeout, $scope, $state, $http, $stateParams, entity, Teams, Groups, Players) {
        var vm = this;

        vm.teams = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.groups = {};
        vm.players = Players.query();
        $scope.myFile = {};
        $scope.teamImage = {};
        $scope.groups = {};
        loadAll();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function loadAll () {
            loadGroups();
        }
        function clear () {
            $state.go('teams', null, { reload: 'teams' });
        }

        function loadGroups()  {
            vm.groups = [];
           var url=  "api/groups?page=0&size=20&sort=id,desc";
            $http.get(url)
                .success( function (data) {
                        vm.groups = data;
                    }
                )
                .error( function () {
                });

        }

        // upload on file select or drop
        $scope.uploadPic = function() {

            var uploadUrl = 'api/teams/save';
            var fd = new FormData();
            if (count_obj($scope.myFile)) {
                fd.append('file', $scope.myFile);
            }
            if (count_obj($scope.teamImage)) {
                fd.append('teamImage', $scope.teamImage);
            }
            fd.append('teams',angular.toJson(vm.teams));
            $http.post(uploadUrl, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
                .success( function (data) {
                        console.log('success');
                        onSaveSuccess(data);
                    }
                )
                .error( function () {
                    onSaveError();
                    console.log('error');
                });
        };

        function save () {
            vm.isSaving = true;
            $scope.uploadPic();
            /*if (vm.teams.id !== null) {
                Teams.update(vm.teams, onSaveSuccess, onSaveError);
            } else {
                Teams.save(vm.teams, onSaveSuccess, onSaveError);
            }*/
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:teamsUpdate', result);
            $state.go('teams', null, { reload: 'teams' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function count_obj(obj){
            var i = 0;
            for(var key in obj){
                ++i;
            }
            return i;
        }

    }
})();
