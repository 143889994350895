(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(translateConfig);

    translateConfig.$inject = ['$translateProvider'];

    function translateConfig($translateProvider) {

        $translateProvider.translations('en', {
            // Player roles
            BATSMAN: 'Batsman',
            OPENING_BATSMAN: 'Opening batsman',
            MIDDLE_ORDER_BATSMAN: 'Middle order batsman',
            TOP_ORDER_BATSMAN: 'Top order batsman',
            ALL_ROUNDER: 'Allrounder',
            BATTING_ALL_ROUNDER: 'Batting allrounder',
            BOWLER: 'Bowler',
            BOWLING_ALL_ROUNDER: 'Bowling allrounder',
            WICKETKEEPER: 'Wicketkeeper',
            WICKETKEEPER_BATSMAN: 'Wicketkeeper batsman',

            // Batting style
            RHB: 'Right-hand bat',
            LHB: 'Left-hand bat',

            // Bowling style
            RMF:'Right-arm medium-fast',
            RM: 'Right-arm medium',
            RF: 'Right-arm fast',
            RFM: 'Right-arm fast medium',
            LMF:'Left-arm medium-fast',
            LM: 'Left-arm medium',
            LF: 'Left-arm fast',
            LFM: 'Left-arm fast medium',
            OB: 'Off break (right-arm)',
            LB:'Leg break (right-arm)',
            SLA: 'Slow left-arm orthodox',
            SLC: 'Slow left-arm chinaman',

            // Out
            'techdirectiveApp.HowOut.NOT_OUT':'NOT OUT',
            'techdirectiveApp.HowOut.HIT_WICKET':'HIT WICKET',
            'techdirectiveApp.HowOut.RUN_OUT':'RUN OUT'
        });
        $translateProvider.translations('de', {
            TITLE: 'Hallo',
            FOO: 'Dies ist ein Paragraph.',
            BUTTON_LANG_EN: 'englisch',
            BUTTON_LANG_DE: 'deutsch'
        });
        $translateProvider.preferredLanguage('en');
        $translateProvider.useSanitizeValueStrategy('escape');

    }
})();
