(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('PlayersDialogController', PlayersDialogController);

    PlayersDialogController.$inject = ['$timeout', '$scope', '$state', 'Upload', '$http', '$stateParams', 'entity', 'Players', 'Teams', 'multipartForm'];

    function PlayersDialogController ($timeout, $scope, $state, Upload, $http, $stateParams, entity, Players, Teams, multipartForm) {
        var vm = this;

        vm.players = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.teams = Teams.query();
        $scope.myFile = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('players', null, { reload: 'players' });
        }

        // upload on file select or drop
        $scope.uploadPic = function(file, deleteImage) {

            var uploadUrl = 'api/players/photos';
            var fd = new FormData();

            if (count_obj(file)) {
                var newFile;
                if (file.type === "image/png") {
                    newFile = Date.now() + '.png';
                } else {
                    newFile = Date.now() + '.jpg';
                }
                fd.append('file', file, newFile);
            }
            if (deleteImage === true) {
                vm.players.photo = null
            }
            fd.append('players',angular.toJson(vm.players));
            $http.post(uploadUrl, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
                .success( function (data) {
                    console.log('success');
                    onSaveSuccess(data);
                    }
                )
                .error( function () {
                    onSaveError();
                    console.log('error');
                });
        };

        vm.deleteImage = null;
        vm.removeImage = removeImage;
        function removeImage(deleteImage){
            $scope.myFile = {};
            vm.deleteImage = deleteImage;
            $scope.uploadPic($scope.myFile, deleteImage);
        }

        function save () {
            vm.isSaving = true;


          //  multipartForm.post(uploadUrl, $scope.file);
            if ($scope.myFile) {
                $scope.uploadPic($scope.myFile);
            }

          /*  if (vm.players.id !== null) {
                Players.update(vm.players, onSaveSuccess, onSaveError);
            } else {
                Players.save(vm.players, onSaveSuccess, onSaveError);
            } */
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:playersUpdate', result);
            $state.go('players', null, { reload: 'players' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dob = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function count_obj(obj){
            var i = 0;
            for(var key in obj){
                ++i;
            }
            return i;
        }
    }
})();
