(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .directive('clubSubMenu', ['$parse', function($parse) {
            return {
                restrict: "E",
                template: "<div class=\"tab-pane\">\n" +
                "                <div class=\"tabbable-line\">\n" +
                "                    <ul class=\"nav nav-tabs\">\n" +
                "                        <li ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
                "                            <a ui-sref=\"clubinfo\" data-target=\"#tab_2_0\" data-toggle=\"tab\">Club Info</a>\n" +
                "                        </li>\n" +
           //     "                        <li ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
            //    "                            <a ui-sref=\"vorstand\" class=\"nav-link nav-toggle\" data-target=\"#tab_2_1\" data-toggle=\"tab\"> Vorstand </a>\n" +
            //    "                        </li>\n" +
                "                        <li ui-sref-active=\"active\" aria-haspopup=\"true\" class=\" \">\n" +
                "                            <a ui-sref=\"clubteam\" class=\"nav-link nav-toggle\" data-target=\"#tab_2_2\" data-toggle=\"tab\"> Club Team </a>\n" +
                "                        </li>\n" +
                "\n" +
                "                    </ul>\n" +
                "                </div>\n" +
                "            </div>"
            };
        }])
})();
