(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('StatsDialogController', StatsDialogController);

    StatsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'Stats', 'Players', 'Fixtures'];

    function StatsDialogController ($timeout, $scope, $stateParams, $state, entity, Stats, Players, Fixtures) {
        var vm = this;

        vm.stats = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.players = Players.query();
        vm.fixtures = Fixtures.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('stats', null, { reload: 'stats' });
        }

        function save () {
            vm.isSaving = true;
            if (vm.stats.id !== null) {
                Stats.update(vm.stats, onSaveSuccess, onSaveError);
            } else {
                Stats.save(vm.stats, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:statsUpdate', result);
            $state.go('stats', null, { reload: 'stats' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
