(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('calendar', {
            parent: 'app',
            url: '/calendar',
            data: {
                pageTitle: 'Calendars'
            },
            views: {
                'content@': {
                    templateUrl: 'app/calendar/calendars.html',
                    controller: 'CalendarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('calendar-detail', {
            parent: 'calendar',
            url: '/{id}',
            data: {
                pageTitle: 'Calendar'
            },
            views: {
                'content@': {
                    templateUrl: 'app/calendar/calendar-detail.html',
                    controller: 'CalendarDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Calendar', function($stateParams, Calendar) {
                    return Calendar.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'calendar',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('calendar-detail.edit', {
            parent: 'calendar-detail',
            url: '/detail/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/calendar/calendar-dialog.html',
                    controller: 'CalendarDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Calendar', '$stateParams', function(Calendar, $stateParams) {
                    return Calendar.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('calendar.new', {
            parent: 'calendar',
            url: '/new',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/calendar/calendar-dialog.html',
                    controller: 'CalendarDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        title: null,
                        start: null,
                        end: null,
                        className: null,
                        allDay: null,
                        active: true,
                        id: null,
                        notify: false
                    };
                }
            }
        })
        .state('calendar.edit', {
            parent: 'calendar',
            url: '/{id}/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/calendar/calendar-dialog.html',
                    controller: 'CalendarDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Calendar', '$stateParams', function(Calendar,$stateParams) {
                    return Calendar.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('calendar.delete', {
            parent: 'calendar',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/calendar/calendar-delete-dialog.html',
                    controller: 'CalendarDeleteController',
                    controllerAs: 'vm',
                    size: '',
                    resolve: {
                        entity: ['Calendar', function(Calendar) {
                            return Calendar.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('calendar', null, { reload: 'calendar' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
