(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .factory('CommentaryItemSearch', CommentaryItemSearch);

    CommentaryItemSearch.$inject = ['$resource'];

    function CommentaryItemSearch($resource) {
        var resourceUrl =  'api/_search/commentary-items/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
