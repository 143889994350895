(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('FixturesDetailController', FixturesDetailController);

    FixturesDetailController.$inject = ['$scope', '$rootScope', '$http', 'ngMeta', '$state', '$stateParams', 'previousState', 'entity', 'Fixtures', 'Tournament'];

    function FixturesDetailController($scope, $rootScope, $http, ngMeta, $state, $stateParams, previousState, entity, Fixtures, Tournament) {
        var vm = this;

        vm.fixtures = entity;
        vm.previousState = previousState.name;

        vm.loadAll = loadAll;

        vm.loadFirstTeamBatting = loadFirstTeamBatting;
        vm.loadFirstTeamBowling = loadFirstTeamBowling;

        vm.loadSecondTeamBatting = loadSecondTeamBatting;
        vm.loadSecondTeamBowling = loadSecondTeamBowling;

        vm.loadFirstInnBowlingStats = loadFirstInnStats;
        vm.loadSecondInnBattingStats = loadSecondInnStats;

        vm.loadFirstTeamPlayers = loadFirstTeamPlayers;
        vm.loadSecondTeamPlayers = loadSecondTeamPlayers;
        vm.loadFirstTeamPlayersToBat = loadFirstTeamPlayersToBat;
        vm.loadSecondTeamPlayersToBat = loadSecondTeamPlayersToBat;

        vm.loadRunRateStats = loadRunRateStats;

        vm.firstTeamBattingStats = [];
        vm.firstTeamBowlingStats = [];
        vm.firstTeamFieldingStats = [];
        vm.secondTeamBattingStats = [];
        vm.secondTeamBowlingStats = [];
        vm.secondTeamFieldingStats = [];

        loadAll();
        function loadAll() {
           // loadFirstTeamPlayers();
           // loadSecondTeamPlayers();
            loadFirstTeamPlayersToBat();
            loadSecondTeamPlayersToBat();

            loadFirstTeamBatting();
            loadFirstTeamBowling();
            loadSecondTeamBatting();
            loadSecondTeamBowling();

            loadFirstInnStats();
            loadSecondInnStats();
            loadRunRateStats();

            loadFirstTeamBattingBarChart();
            loadFirstTeamBowlingBarChart();
            loadSecondTeamBattingBarChart();
            loadSecondTeamBowlingBarChart();
            loadRunRateStatsChart();

            ngMeta.setTitle('Fixture | '+ vm.fixtures.matchNo + ' ' + vm.fixtures.tournament.name + ' ' + vm.fixtures.startDate );
            ngMeta.setTag('keyword', vm.fixtures.tournament.name);
            ngMeta.setTag('description', vm.fixtures.matchNo + ' | ' + vm.fixtures.tournament.name + ' | ' + vm.fixtures.startDate + ' | ' +
            vm.fixtures.matchStatus);
        }

        vm.firstTeamBattingStatsPlayerNames = [];
        vm.firstTeamBattingStatsBallsFaced = [];
        vm.firstTeamBattingStatsRunsScored = [];
        function loadFirstTeamBatting() {
            if (vm.fixtures.firstTeam!==null) {
                vm.firstTeamBattingStatsPlayerNames = [];
                vm.firstTeamBattingStatsBallsFaced = [];
                vm.firstTeamBattingStatsRunsScored = [];
                vm.firstTeamBattingStats = [];
                var params = $.param({
                    fixtureId:vm.fixtures.id,
                    teamId:vm.fixtures.firstTeam.id
                });
                var url = 'api/batting-stats/fixtureId?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.firstTeamBattingStats = data;
                            var fbHeight = 100;
                            angular.forEach(data.slice().reverse(), function (value, key) {
                                vm.firstTeamBattingStatsPlayerNames.push(value.players.name);
                                vm.firstTeamBattingStatsBallsFaced.push(value.ballsFaced);
                                vm.firstTeamBattingStatsRunsScored.push(value.runsScored);
                                fbHeight = fbHeight + 110;
                            });
                        vm.firstTeamBattingH = fbHeight;
                        }
                    )
                    .error( function () {
                    });
            }

        }

        vm.firstTeamBowlingStatsPlayersName = [];
        vm.firstTeamBowlingStatsOversBowled = [];
        vm.firstTeamBowlingStatsRunsGiven = [];
        vm.firstTeamBowlingStatsWicketTaken = [];
        function loadFirstTeamBowling() {
            if(vm.fixtures.firstTeam!==null) {
                vm.firstTeamBowlingStatsPlayersName = [];
                vm.firstTeamBowlingStatsOversBowled = [];
                vm.firstTeamBowlingStatsRunsGiven = [];
                vm.firstTeamBowlingStatsWicketTaken = [];
                vm.firstTeamBowlingStats = [];
                var params = $.param({
                    fixtureId:vm.fixtures.id,
                    teamId:vm.fixtures.firstTeam.id
                });
                var url = 'api/bowling-stats/fixtureId?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.firstTeamBowlingStats = data;
                            angular.forEach(data, function (value, key) {
                                vm.firstTeamBowlingStatsPlayersName.push(value.players.name);
                                vm.firstTeamBowlingStatsOversBowled.push(value.oversBowled !== null?value.oversBowled:0);
                                vm.firstTeamBowlingStatsRunsGiven.push(value.runsGave!==null?value.runsGave:0);
                                vm.firstTeamBowlingStatsWicketTaken.push(value.wickets!==null?value.wickets:0);
                            });
                        }
                    )
                    .error( function () {
                    });
            }

        }

        vm.secondTeamBattingStatsPlayerNames = [];
        vm.secondTeamBattingStatsBallsFaced = [];
        vm.secondTeamBattingStatsRunsScored = [];

        function loadSecondTeamBatting() {
            if(vm.fixtures.secondTeam!==null) {
                vm.secondTeamBattingStatsPlayerNames = [];
                vm.secondTeamBattingStatsBallsFaced = [];
                vm.secondTeamBattingStatsRunsScored = [];
                vm.secondTeamBattingStats = [];
                var params = $.param({
                    fixtureId:vm.fixtures.id,
                    teamId:vm.fixtures.secondTeam.id
                });
                var url = 'api/batting-stats/fixtureId?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.secondTeamBattingStats = data;
                            var fbHeight = 100;
                            angular.forEach(data.slice().reverse(), function (value, key) {
                                vm.secondTeamBattingStatsPlayerNames.push(value.players.name);
                                vm.secondTeamBattingStatsBallsFaced.push(value.ballsFaced);
                                vm.secondTeamBattingStatsRunsScored.push(value.runsScored);
                                fbHeight = fbHeight + 110;
                            });
                        vm.secondTeamBattingH = fbHeight;
                        }
                    )
                    .error( function () {
                    });
            }

        }

        vm.secondTeamBowlingStatsPlayersName = [];
        vm.secondTeamBowlingStatsOversBowled = [];
        vm.secondTeamBowlingStatsRunsGiven = [];
        vm.secondTeamBowlingStatsWicketTaken = [];
        function loadSecondTeamBowling() {
            if (vm.fixtures.secondTeam!==null) {
                vm.secondTeamBowlingStatsPlayersName = [];
                vm.secondTeamBowlingStatsOversBowled = [];
                vm.secondTeamBowlingStatsRunsGiven = [];
                vm.secondTeamBowlingStatsWicketTaken = [];
                vm.secondTeamBowlingStats = [];
                var params = $.param({
                    fixtureId:vm.fixtures.id,
                    teamId:vm.fixtures.secondTeam.id
                });
                var url = 'api/bowling-stats/fixtureId?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.secondTeamBowlingStats = data;
                            angular.forEach(data, function (value, key) {
                                vm.secondTeamBowlingStatsPlayersName.push(value.players.name);
                                vm.secondTeamBowlingStatsOversBowled.push(value.oversBowled !== null?value.oversBowled:0);
                                vm.secondTeamBowlingStatsRunsGiven.push(value.runsGave!==null?value.runsGave:0);
                                vm.secondTeamBowlingStatsWicketTaken.push(value.wickets!==null?value.wickets:0);
                            });
                        }
                    )
                    .error( function () {
                    });
            }

        }

        vm.firstTeamPlayers =  [];
        vm.secondTeamPlayers = [];

        function loadFirstTeamPlayers() {
            if (vm.fixtures.firstTeam!==null) {
                vm.firstTeamPlayers = [];
                var params = $.param({
                    id: vm.fixtures.firstTeam.id
                });
                var url = 'api/players/playersByTeamId?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.firstTeamPlayers = data;

                        }
                    )
                    .error( function () {
                    });
            }

        }

        function loadSecondTeamPlayers() {
            if (vm.fixtures.secondTeam!==null) {
                vm.secondTeamPlayers = [];
                var params = $.param({
                    id: vm.fixtures.secondTeam.id
                });
                var url = 'api/players/playersByTeamId?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.secondTeamPlayers = data;

                        }
                    )
                    .error( function () {
                    });
            }

        }

        function loadFirstTeamPlayersToBat() {
            // load first team players to bat
            vm.firstTeamPlayersToBat = [];
            if (vm.teamId!==null && vm.fixtures.firstTeam !== null) {
                var params = $.param({
                    fixtureId:vm.fixtures.id,
                    teamId:vm.fixtures.firstTeam.id
                });
                var url = 'api/players/playersByTeamIdAndBattingS?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.firstTeamPlayersToBat = data;
                        }
                    )
                    .error( function () {
                    });
            }
        }

        function loadSecondTeamPlayersToBat() {
            // load second team players to bat
            vm.secondTeamPlayersToBat = [];
            if (vm.teamId!==null && vm.fixtures.secondTeam !== null) {
                var params = $.param({
                    fixtureId:vm.fixtures.id,
                    teamId:vm.fixtures.secondTeam.id
                });
                var url = 'api/players/playersByTeamIdAndBattingS?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.secondTeamPlayersToBat = data;
                        }
                    )
                    .error( function () {
                    });
            }
        }

        vm.firstInnBattingStats = [];
        vm.firstInnBowlingStats = [];
        vm.secondInnBattingStats = [];
        vm.secondInnBowlingStats = [];

        // load first inning batting and bowling stats
        function loadFirstInnStats() {
            if (vm.fixtures.firstTeam!==null) {
                vm.firstInnBowlingStats = [];
                vm.firstInnBattingStats = [];
                var params = $.param({
                    id : vm.fixtures.firstTeam.id,
                    fixturesId: vm.fixtures.id
                });
                var url = 'api/fixtures/findByIdAndFixturesId?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.firstInnBowlingStats = data.bowlingStats;
                            vm.firstInnBattingStats = data.battingStats;
                        }
                    )
                    .error( function () {
                    });
            }

        }

        // load second inning batting and bowling stats
        function loadSecondInnStats() {
            if (vm.fixtures.secondTeam!==null) {
                vm.secondInnBattingStats = [];
                vm.secondInnBowlingStats = [];
                var params = $.param({
                    id : vm.fixtures.secondTeam.id,
                    fixturesId: vm.fixtures.id
                });
                var url = 'api/fixtures/findByIdAndFixturesId?';
                $http.get(url + params)
                    .success( function (data) {
                        vm.secondInnBowlingStats = data.bowlingStats;
                        vm.secondInnBattingStats = data.battingStats;
                        }
                    )
                    .error( function () {
                    });
            }

        }

        vm.f1Overs = [];
        vm.f2Overs = [];
        vm.f1Wickets = [];
        vm.f2Wickets = [];
        vm.f1RunsPerOver = [];
        vm.f2RunsPerOver = [];
        vm.f1RunRate = [];
        vm.f2RunRate = [];

        vm.m1Overs = [];
        vm.m2Overs = [];
        vm.m1Wickets = [];
        vm.m2Wickets = [];
        vm.m1RunsPerOver = [];
        vm.m2RunsPerOver = [];
        vm.m1RunRate = [];
        vm.m2RunRate = [];
        /**
         * load run rate stats
         */
        function loadRunRateStats() {
            if(vm.fixtures!==null && vm.fixtures.firstTeam !== null && vm.fixtures.secondTeam !== null) {
                vm.f1Overs = [];
                vm.f2Overs = [];
                vm.f1Wickets = [];
                vm.f2Wickets = [];
                vm.f1RunsPerOver = [];
                vm.f2RunsPerOver = [];
                vm.f1RunRate = [];
                vm.f2RunRate = [];
                var firstTeamId = vm.fixtures.firstTeam.id;
                var secondTeamId = vm.fixtures.secondTeam.id;
                var params = $.param({
                    fixturesId:vm.fixtures.id
                });
                var url = 'api/fixtures/findRunRateStats?';
                $http.get(url + params)
                    .success( function (data) {
                        vm.runRateStats = data;
                        vm.runRateStats1 = [];
                        vm.runRateStats2 = [];
                        Object.keys(data).forEach(function(key) {
                            if (Number(key) === firstTeamId) {
                                // work around to start from 0 index
                                vm.runRateStats1 = data[firstTeamId];
                                vm.f1Overs.push(0);
                                vm.f1Wickets.push(0);
                                vm.f1RunsPerOver.push(0);
                                vm.f1RunRate.push(0);
                                angular.forEach(vm.runRateStats1, function (v, key) {
                                    vm.f1Overs.push(v.overIndex);
                                    vm.f1Wickets.push(v.fWickets);
                                    vm.f1RunsPerOver.push(v.runsPerOver);
                                    vm.f1RunRate.push(v.runRate.toFixed(2));
                                    v.name = vm.fixtures.firstTeam.name;

                                    // manhathan
                                    vm.m1Overs.push(v.overIndex);
                                    vm.m1Wickets.push(v.fWickets);
                                   // vm.m1RunsPerOver.push(v.runsPerOver);
                                     if (key > 0) {
                                         var runs = vm.runRateStats1[key-1].runsPerOver;
                                         vm.m1RunsPerOver.push(v.runsPerOver - runs);
                                         if (vm.runRateStats1[key-1].fWickets>0) {
                                             var wickets = vm.runRateStats1[key-1].fWickets;
                                             vm.m1Wickets.push(v.fWickets - wickets);
                                         }
                                     } else {
                                         vm.m1RunsPerOver.push(v.runsPerOver);
                                     }
                                });
                            }
                            if (Number(key) === secondTeamId) {
                                // work around to start from 0 index
                                vm.runRateStats2 = data[secondTeamId];
                                vm.f2Overs.push(0);
                                vm.f2Wickets.push(0);
                                vm.f2RunsPerOver.push(0);
                                vm.f2RunRate.push(0);
                                angular.forEach(vm.runRateStats2, function (v, key) {
                                    vm.f2Overs.push(v.overIndex);
                                    vm.f2Wickets.push(v.fWickets);
                                    vm.f2RunsPerOver.push(v.runsPerOver);
                                    vm.f2RunRate.push(v.runRate.toFixed(2));
                                    v.name = vm.fixtures.secondTeam.name;

                                    // manhathan
                                    vm.m2Overs.push(v.overIndex);
                                    vm.m2Wickets.push(v.fWickets);
                                    // vm.m2RunsPerOver.push(v.runsPerOver);
                                     if (key > 0) {
                                         var runs = vm.runRateStats2[key-1].runsPerOver;
                                         vm.m2RunsPerOver.push(v.runsPerOver - runs);
                                         if (vm.runRateStats2[key-1].fWickets>0) {
                                             var wickets = vm.runRateStats2[key-1].fWickets;
                                             vm.m2Wickets.push(v.fWickets - wickets);
                                         }
                                     } else {
                                         vm.m2RunsPerOver.push(v.runsPerOver);
                                     }
                                });
                            }
                        });
                        }
                    )
                    .error( function () {
                    });
            }

        }

        vm.loadRunsPerOverStatsChart = loadRunsPerOverStatsChart;
        function loadRunsPerOverStatsChart() {
            // based on prepared DOM, initialize echarts instance
            var myChart = echarts.init(document.getElementById('wormstats'), 'macarons');
            var lineitemStyle1 = {
                normal: {
                    label: {
                        formatter: function() {
                            return dataValue - params.value;
                        },
                        fontSize: 40,
                        padding: [90, 0, 0, 0],
                        color: '#fff',
                        textStyle: {
                            baseline: 'top'
                        }
                    },
                    color: '#b6a2de',
                    borderColor: 'rgba(182, 162, 222, 0.5)',
                    borderWidth: 10
                }
            };
            var lineitemStyle2 = {
                normal: {
                    label: {
                        formatter: function(params) {
                            return dataValue - params.value;
                        },
                        fontSize: 40,
                        padding: [90, 0, 0, 0],
                        color: '#fff',
                        textStyle: {
                            baseline: 'top'
                        }
                    },
                    color: '#2ec7c9',
                    borderColor: 'rgba(46, 199, 201, 0.5)',
                    borderWidth: 10
                }
            };
            var option = {
                title: {
                    text: 'Worm',
                    textStyle: {
                        color: '#000'
                    },
                    x: 'left'
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(245, 245, 245, 0.8)',
                    borderWidth: 1,
                    borderColor: '#ccc',
                    padding: 10,
                    textStyle: {
                        color: '#000'
                    },
                    formatter:function(name){
                        var oa1 = vm.runRateStats1;
                        var oa2 = vm.runRateStats2;
                        var s1Str = '';
                        var s2Str = '';

                        var oas1 = vm.runRateStats1[0].name;
                        for (var p = 0; p < name.length; p++) {
                            if (name[p].seriesName === oas1) {
                                for (var i = 0; i < vm.runRateStats1.length; i++) {
                                    if (name[p].dataIndex -1 === i) {
                                        s1Str = 'Over: ' + name[p].dataIndex + '<br/><span style="color: #b6a2de" >' + name[p].seriesName + ': ' + vm.runRateStats1[i].runsPerOver + '<br/> Wkts: ' + vm.runRateStats1[i].fWickets + '</span>';
                                    }
                                }
                            } else {
                                for (var i = 0; i < vm.runRateStats2.length; i++) {
                                    if (name[p].dataIndex -1 === i) {
                                        s2Str = 'Over: ' + name[p].dataIndex + '<br/><span style="color: #2ec7c9" >' + name[p].seriesName + ': ' + vm.runRateStats2[i].runsPerOver + '<br/> Wkts: ' + vm.runRateStats2[i].fWickets + '</span>';
                                    }
                                }
                            }
                        }
                        return s1Str + '<br/>' + s2Str;
                    }
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        magicType: {show: true, type: ['line', 'bar'], title:{line:"line",bar:"bar"}},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                },
                legend: {
                    x : 'center',
                    y : 'bottom',
                    data: [vm.fixtures.firstTeam!==null?vm.fixtures.firstTeam.name:'', vm.fixtures.secondTeam!==null?vm.fixtures.secondTeam.name:'']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    nameLocation: 'middle',
                    nameGap: 30,
                    name: 'OVERS',
                    nameTextStyle: {
                        fontSize: 15,
                        color: '#8896a0'
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    },
                    type : 'category',
                    boundaryGap : false,
                    scale: false,
                    data : vm.f1Overs
                },
                yAxis: [{
                    type : 'value',
                    nameTextStyle: {
                        fontSize: 15,
                        color: '#8896a0'
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dotted',
                            color: '#1978D9'
                        }
                    },
                    scale: false,
                    name: 'RUNS',
                    nameLocation: 'middle',
                    nameGap: 30
                }],
                series: [
                    {
                        name: vm.fixtures.firstTeam!==null?vm.fixtures.firstTeam.name:'',
                        type: 'line',
                        smooth: true,
                        symbolSize: 6,
                        symbol: 'circle',
                        itemStyle: lineitemStyle1,
                        data: vm.f1RunsPerOver
                    },
                    {
                        name: vm.fixtures.secondTeam!==null?vm.fixtures.secondTeam.name:'',
                        type: 'line',
                        smooth: true,
                        symbolSize: 6,
                        symbol: 'circle',
                        itemStyle: lineitemStyle2,
                        data: vm.f2RunsPerOver
                    }
                ]
            };

            // use configuration item and data specified to show chart
            myChart.setOption(option);
            myChart.resize();
            return myChart;

        }

        vm.loadRunRateStatsChart = loadRunRateStatsChart;
        function loadRunRateStatsChart() {
            // based on prepared DOM, initialize echarts instance
            var myChart = echarts.init(document.getElementById('runratestats'), 'macarons');
            var lineitemStyle1 = {
                normal: {
                    label: {
                        formatter: function(params) {
                            return dataValue - params.value;
                        },
                        fontSize: 40,
                        padding: [90, 0, 0, 0],
                        color: '#fff',
                        textStyle: {
                            baseline: 'top'
                        }
                    },
                    color: '#b6a2de',
                    borderColor: 'rgba(182, 162, 222, 0.5)',
                    borderWidth: 10
                }
            };
            var lineitemStyle2 = {
                normal: {
                    label: {
                        formatter: function(params) {
                            return dataValue - params.value;
                        },
                        fontSize: 40,
                        padding: [90, 0, 0, 0],
                        color: '#fff',
                        textStyle: {
                            baseline: 'top'
                        }
                    },
                    color: '#2ec7c9',
                    borderColor: 'rgba(46, 199, 201, 0.5)',
                    borderWidth: 10
                }
            };
            var option = {
                title: {
                    text: 'Run rate graph',
                    textStyle: {
                        color: '#000'
                    },
                    x: 'left'
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(245, 245, 245, 0.8)',
                    borderWidth: 1,
                    borderColor: '#ccc',
                    padding: 10,
                    textStyle: {
                        color: '#000'
                    },
                   // trigger: 'item',
                   // formatter: 'Over: {b1} <br/> {a0}: {c0}<br/> {a1}: {c1}'// ({d}%)'
                    formatter:function(name){
                        var oa1 = vm.runRateStats1;
                        var oa2 = vm.runRateStats2;
                        var s1Str = '';
                        var s2Str = '';

                        var oas1 = vm.runRateStats1[0].name;
                        for (var p = 0; p < name.length; p++) {
                            if (name[p].seriesName === oas1) {
                                for (var i = 0; i < vm.runRateStats1.length; i++) {
                                    if (name[p].dataIndex -1 === i) {
                                        s1Str = 'Over: ' + name[p].dataIndex + '<br/><span style="color: #b6a2de" >' + name[p].seriesName + ': ' + vm.runRateStats1[i].runRate.toFixed(2) + '<br/> Wkts: ' + vm.runRateStats1[i].fWickets + '</span>';
                                    }
                                }
                            } else {
                                for (var i = 0; i < vm.runRateStats2.length; i++) {
                                    if (name[p].dataIndex -1 === i) {
                                        s2Str = 'Over: ' + name[p].dataIndex + '<br/><span style="color: #2ec7c9" >' + name[p].seriesName + ': ' + vm.runRateStats2[i].runRate.toFixed(2) + '<br/> Wkts: ' + vm.runRateStats2[i].fWickets + '</span>';
                                    }
                                }
                            }
                        }
                        return s1Str + '<br/>' + s2Str;
                       /* if (oas === oas1) {
                            for (var i = 0; i < vm.runRateStats1.length; i++) {
                                if (name.dataIndex -1 === i) {
                                    return 'Over: ' + name.dataIndex + '<br/>' + name.seriesName + ': ' + vm.runRateStats1[i].runRate.toFixed(2) + '<br/> Wkts: ' + vm.runRateStats1[i].fWickets;
                                }
                            }
                        } else {
                            for (var i = 0; i < vm.runRateStats2.length; i++) {
                                if (name.dataIndex -1 === i) {
                                    return 'Over: ' + name.dataIndex + '<br/>' + name.seriesName + ': ' + vm.runRateStats2[i].runRate.toFixed(2) + '<br/> Wkts: ' + vm.runRateStats2[i].fWickets;
                                }
                            }
                        }*/
                        /*for (var p = 0; p < name.length; p++) {
                            for (var i = 0; i < oa1.length; i++) {
                                if(name[p].seriesName===oa1[i].name){
                                    s1Str =  'Over: ' + oa1[i].overIndex +'<br/>'+ name[p].seriesName + ': ' + oa1[i].runRate.toFixed(2) + '<br/> Wkts: ' + oa1[i].fWickets ;
                                }
                            }

                            for (var j = 0; j < oa2.length; j++) {
                                if(name[p].seriesName===oa2[j].name){
                                    s2Str =  'Over: ' + oa2[j].overIndex +'<br/>'+ name[p].seriesName + ': ' + oa2[j].runRate.toFixed(2) + '<br/> Wkts: ' + oa2[j].fWickets ;
                                }
                            }
                        }

                        return s1Str + s2Str;*/

                    }
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        magicType: {show: true, type: ['line', 'bar'], title:{line:"line",bar:"bar"}},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                },
                legend: {
                    x : 'center',
                    y : 'bottom',
                    data: [vm.fixtures.firstTeam!==null?vm.fixtures.firstTeam.name:'', vm.fixtures.secondTeam!==null?vm.fixtures.secondTeam.name:'']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    nameLocation: 'middle',
                    nameGap: 30,
                    name: 'OVERS',
                    nameTextStyle: {
                        fontSize: 15,
                        color: '#8896a0'
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    type : 'category',
                    boundaryGap : false,
                    scale: false,
                    data : vm.f1Overs
                },
                yAxis: [{
                    type : 'value',
                    nameTextStyle: {
                        fontSize: 15,
                        color: '#8896a0'
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dotted',
                            color: '#1978D9'
                        }
                    },
                    scale: false,
                    name: 'RUN RATE',
                    nameLocation: 'middle',
                    nameGap: 30
                }],
                series: [
                    {
                        name: vm.fixtures.firstTeam!==null?vm.fixtures.firstTeam.name:'',
                        type: 'line',
                        smooth: true,
                        symbolSize: 6,
                        symbol: 'circle',
                        itemStyle: lineitemStyle1,
                        data: vm.f1RunRate
                    },
                    {
                        name: vm.fixtures.secondTeam!==null?vm.fixtures.secondTeam.name:'',
                        type: 'line',
                        smooth: true,
                        symbolSize: 6,
                        symbol: 'circle',
                        itemStyle: lineitemStyle2,
                        data: vm.f2RunRate
                    }
                ]
            };

            // use configuration item and data specified to show chart
            myChart.setOption(option);
            myChart.resize();
            return myChart;

        }

        vm.loadManhattanBarChart = loadManhattanBarChart;
        function loadManhattanBarChart() {

            // based on prepared DOM, initialize echarts instance
            var myChart = echarts.init(document.getElementById('manhattanBarChart'),'macarons');
            var firstTeamName = vm.fixtures.firstTeam!==null?vm.fixtures.firstTeam.name:'';
            var secondTeamName = vm.fixtures.secondTeam!==null?vm.fixtures.secondTeam.name:'';
            var score = vm.fixtures.runsScoredBy1stTeam + "/" + vm.fixtures.wicketsFirstTeam;
            var option = {
                title: {
                    text: 'Manhattan',
                    textStyle: {
                        color: '#000'
                    },
                    x: 'left'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        magicType: {show: true, type: ['line', 'bar'], title:{line:"line",bar:"bar"}},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                },
                calculable : true,
                legend: {
                    x : 'center',
                    y : 'bottom',
                    data: [firstTeamName, secondTeamName]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis : [
                    {
                        type : 'category',
                        data: vm.m2Overs>vm.m2Overs?vm.m1Overs:vm.m1Overs,
                        name: 'OVERS',
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontSize: 15,
                            color: '#8896a0'
                        },
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            interval:0,
                            // rotate:30,
                            margin: 30,
                            textStyle:{
                                align: 'center',
                                color: '#000',
                                fontSize: 14

                            }
                        }
                    }
                ],
                yAxis: [{
                    nameTextStyle: {
                        fontSize: 15,
                        color: '#8896a0'
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    },
                    scale: false,
                    name: 'RUNS',
                    nameLocation: 'middle',
                    nameGap: 30
                }],

                series: [
                    {
                        name: secondTeamName,
                        type: 'bar',
                        data: vm.m2RunsPerOver,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideTop'
                            }
                        }
                    },
                    {
                        name: firstTeamName,
                        type: 'bar',
                        data: vm.m1RunsPerOver,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideTop'
                            }
                        }
                    }
                ]
            };

            // use configuration item and data specified to show chart
            myChart.setOption(option);
            myChart.resize();
            return myChart;

        }


        vm.loadFirstTeamBattingBarChart = loadFirstTeamBattingBarChart;
        // Bar Chart
        function loadFirstTeamBattingBarChart() {

            // based on prepared DOM, initialize echarts instance
            var myChart = echarts.init(document.getElementById('firstTeamBatting'), 'macarons');
            var name = vm.fixtures.firstTeam!==null?vm.fixtures.firstTeam.name:'';
            var score = vm.fixtures.runsScoredBy1stTeam + "/" + vm.fixtures.wicketsFirstTeam;
            var option = {
                title: {
                    text: 'Batting: ' + name +':' + score,
                    textStyle: {
                        color: '#000'
                    },
                    x: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        magicType: {show: true, type: ['line', 'bar'], title:{line:"line",bar:"bar"}},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                },
                calculable : true,
                legend: {
                    x : 'center',
                    y : 'bottom',
                    data: ['Balls faced', 'Runs scored']
                },
                grid: {
                    top: '12%',
                    left: '1%',
                    right: '10%',
                    bottom: '80',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    name: 'Balls/Runs',
                    nameLocation: 'middle',
                    nameGap: 40,
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 15,
                        color: '#000'
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    data: vm.firstTeamBattingStatsPlayerNames,
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    }
                },
                series: [
                    {
                        name: 'Balls faced',
                        type: 'bar',
                        data: vm.firstTeamBattingStatsBallsFaced,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideRight'
                            }
                        }
                    },
                    {
                        name: 'Runs scored',
                        type: 'bar',
                        data: vm.firstTeamBattingStatsRunsScored,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideRight'
                            }
                        }
                    }
                ]
            };

            // use configuration item and data specified to show chart
            myChart.setOption(option);
            myChart.resize();
            return myChart;

        }

        vm.loadFirstTeamBowlingBarChart = loadFirstTeamBowlingBarChart;
        // Bar Chart
        function loadFirstTeamBowlingBarChart() {

            // based on prepared DOM, initialize echarts instance
            var myChart = echarts.init(document.getElementById('firstTeamBowling'), 'macarons');
            var name = vm.fixtures.firstTeam!==null?vm.fixtures.firstTeam.name:'';
            var option = {
                title: {
                    text: 'Bowling: ' + name,
                    textStyle: {
                        color: '#000'
                    },
                    x: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        magicType: {show: true, type: ['line', 'bar'], title:{line:"line",bar:"bar"}},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                },
                calculable : true,
                legend: {
                    x : 'center',
                    y : 'bottom',
                    data: ['Overs Bowled', 'Runs Conceded', 'Wickets']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis : [
                    {
                        type : 'category',
                        data : vm.firstTeamBowlingStatsPlayersName,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            interval:0,
                           // rotate:30,
                            margin: 30,
                            textStyle:{
                                align: 'center',
                                color: '#000',
                                fontSize: 14

                            }
                        }
                    }
                ],
                yAxis: {
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    }
                },
                series: [
                    {
                        name: 'Overs Bowled',
                        type: 'bar',
                        data: vm.firstTeamBowlingStatsOversBowled,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideTop'
                            }
                        }
                    },
                    {
                        name: 'Runs Conceded',
                        type: 'bar',
                        data: vm.firstTeamBowlingStatsRunsGiven,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideTop'
                            }
                        }
                    },
                    {
                        name: 'Wickets',
                            type: 'bar',
                        data: vm.firstTeamBowlingStatsWicketTaken,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideTop'
                            }
                        }
                    }
                ]
            };


            // use configuration item and data specified to show chart
            myChart.setOption(option);
            myChart.resize();
            return myChart;

        }


        vm.loadSecondTeamBattingBarChart = loadSecondTeamBattingBarChart;
        // Bar Chart
        function loadSecondTeamBattingBarChart() {

            // based on prepared DOM, initialize echarts instance
            var myChart = echarts.init(document.getElementById('secondTeamBatting'), 'macarons');
            var name = vm.fixtures.secondTeam!==null?vm.fixtures.secondTeam.name:'';
            var score = vm.fixtures.runsScoredBy2ndTeam + "/" + vm.fixtures.wicketsSecondTeam;
            var option = {
                title: {
                    text: 'Batting: ' + name +':' + score,
                    textStyle: {
                        color: '#000'
                    },
                    x: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        magicType: {show: true, type: ['line', 'bar'], title:{line:"line",bar:"bar"}},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                },
                calculable : true,
                legend: {
                    x : 'center',
                    y : 'bottom',
                    data: ['Balls faced', 'Runs scored']
                },
                grid: {
                    top: '12%',
                    left: '1%',
                    right: '10%',
                    bottom: '80',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    name: 'Balls/Runs',
                    nameLocation: 'middle',
                    nameGap: 40,
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 15,
                        color: '#000'
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    }

                },
                yAxis: {
                    type: 'category',
                    data: vm.secondTeamBattingStatsPlayerNames,
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    }
                },
                series: [
                    {
                        name: 'Balls faced',
                        type: 'bar',
                        data: vm.secondTeamBattingStatsBallsFaced,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideRight'
                            }
                        }
                    },
                    {
                        name: 'Runs scored',
                        type: 'bar',
                        data: vm.secondTeamBattingStatsRunsScored,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideRight'
                            }
                        }
                    }
                ]
            };

            // use configuration item and data specified to show chart
            myChart.setOption(option);
            myChart.resize();

        }

        vm.loadSecondTeamBowlingBarChart = loadSecondTeamBowlingBarChart;
        // Bar Chart
        function loadSecondTeamBowlingBarChart() {

            // based on prepared DOM, initialize echarts instance
            var myChart = echarts.init(document.getElementById('secondTeamBowling'), 'macarons');
            var name = vm.fixtures.secondTeam!==null?vm.fixtures.secondTeam.name:'';
            var option = {
                title: {
                    text: 'Bowling: ' + name,
                    textStyle: {
                        color: '#000'
                    },
                    x: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        magicType: {show: true, type: ['line', 'bar'], title:{line:"line",bar:"bar"}},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                },
                calculable : true,
                legend: {
                    x : 'center',
                    y : 'bottom',
                    data: ['Overs Bowled', 'Runs Conceded', 'Wickets']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis : [
                    {
                        type : 'category',
                        data : vm.secondTeamBowlingStatsPlayersName,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            interval:0,
                           // rotate:30,
                            margin: 30,
                            textStyle:{
                                align: 'center',
                                color: '#000',
                                fontSize: 14
                            }
                        }
                    }
                ],
                yAxis: {
                    axisLabel: {
                        textStyle: {
                            color: '#000',
                            fontSize: 14
                        }
                    }
                },
                series: [
                    {
                        name: 'Overs Bowled',
                        type: 'bar',
                        data: vm.secondTeamBowlingStatsOversBowled,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideTop'
                            }
                        }
                    },
                    {
                        name: 'Runs Conceded',
                        type: 'bar',
                        data: vm.secondTeamBowlingStatsRunsGiven,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideTop'
                            }
                        }
                    },
                    {
                        name: 'Wickets',
                        type: 'bar',
                        data: vm.secondTeamBowlingStatsWicketTaken,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideTop'
                            }
                        }
                    }
                ]
            };


            // use configuration item and data specified to show chart
            myChart.setOption(option);
            myChart.resize();

        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:fixturesUpdate', result);

            $state.go('fixtures-detail', null, { reload: 'fixtures-detail' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        var charts = {};

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            var current = $(e.target).attr('href').slice(1),
                previous = $(e.relatedTarget).attr('href').slice(1),
                chart = $('#' + current).find('.graphs');
            if (charts[previous]) {
                charts[previous].dispose();
                charts[previous] = null;
            }
            if (chart) {
                charts[current] = graph(chart);
            }

        });

        function graph(chart) {
            loadFirstTeamBattingBarChart();
            loadFirstTeamBowlingBarChart();
            loadSecondTeamBattingBarChart();
            loadSecondTeamBowlingBarChart();
            loadRunRateStatsChart();
            loadRunsPerOverStatsChart();
            loadManhattanBarChart();
        }

        var unsubscribe = $rootScope.$on('techdirectiveApp:fixturesUpdate', function(event, result) {
            vm.fixtures = result;
        });
        $scope.$on('$destroy', unsubscribe);


        // PDF download

        $scope.downloadGameStats = function() {
            html2canvas(document.getElementById('gamestats'), {
                onrendered: function(canvas) {
                    var filename = vm.fixtures.tournament.name + "_" + vm.fixtures.firstTeam.name+"_vs_"+vm.fixtures.secondTeam.name+"_"+vm.fixtures.matchNo+".pdf";
                    var data = canvas.toDataURL();
                    var docDefinition = {
                        content: [{
                            image: data,
                            width: 520,
                            height:780
                        }]
                    };
                    pdfMake.createPdf(docDefinition).download(filename);
                }
            });
        };
    }
})();
