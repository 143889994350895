(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('GroupsDetailController', GroupsDetailController);

    GroupsDetailController.$inject = ['$scope', '$http', '$rootScope', '$stateParams', 'previousState', 'entity', 'Groups', 'Tournament', 'Teams'];

    function GroupsDetailController($scope, $http, $rootScope, $stateParams, previousState, entity, Groups, Tournament, Teams) {
        var vm = this;

        vm.groups = entity;
        vm.previousState = previousState.name;
        vm.selectedTeamId = null;
        vm.selectedTeam = null;

        var unsubscribe = $rootScope.$on('techdirectiveApp:groupsUpdate', function(event, result) {
            vm.groups = result;
        });
        $scope.$on('$destroy', unsubscribe);


        vm.autoCompleteOptions = {
            minimumChars: 1,
            activateOnFocus: true,
            data: function (searchText) {
                var params = $.param({
                    name: searchText
                });
                vm.selectedTeamData =[];
                var url = 'api/teams/teamsByName?';
                return $http.get(url + params)
                    .then(function (response) {
                        // searchText = searchText.toUpperCase();

                        // ideally filtering should be done on the server
                        var teams = _.filter(response.data, function (team) {
                            vm.selectedTeamId = team.id;
                            vm.selectedTeamData = response.data;
                            return team.name.toLowerCase().startsWith(searchText) || team.name.startsWith(searchText);
                        });

                        return _.pluck(teams, 'name');
                    });
            },
            itemSelected: function (e) {
                angular.forEach(vm.selectedTeamData, function (val) {
                    if (e.item === val.name) {
                        vm.selectedTeamId = val.id;
                    }
                });
            }
        };

        vm.addTeam = addTeam;
        //  function addPlayer() {
        //      console.log("selected player id: " + vm.selectedPlayerId);
        //      console.log("selected team id: " + vm.teams.id);
        //  }

        function addTeam() {
            console.log("selected team id: " + vm.selectedTeamId);
            console.log("selected group id: " + vm.groups.id);
            var url = 'api/teams/addTeam?';
            var params = $.param({
                groupId:vm.groups.id,
                teamId:vm.selectedTeamId
            });
            $http.put(url + params)
                .success(function (data) {
                        vm.groups = data;
                        clear();
                    }
                )
                .error( function () {
                });


        }

        vm.removeTeam = removeTeam;
        function removeTeam(id) {
            var url = 'api/teams/removeTeam?';
            var params = $.param({
                groupId:vm.groups.id,
                teamId:id
            });
            $http.put(url + params)
                .success(function (data) {
                        vm.groups = data;
                        clear();
                    }
                )
                .error( function () {
                });

        }

        vm.clear = clear;
        function clear() {
            vm.selectedTeam = null;
            vm.selectedTeamId = null;
        }
    }
})();
