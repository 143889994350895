(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .directive('backgroundImageDirective', ['$parse', function($parse) {
            return function (scope, element, attrs) {
                var newstr = attrs.backgroundImageDirective.replace(/\\/g, '/');
               // newstr = newstr.replace(/\\/, '/');
                element.css({
                    'background-image': 'url(' + newstr + ')',
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center center'
                });
            };
        }])
})();
