(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('points-table', {
            parent: 'cmp',
            url: '/points-table?page&sort&search',
            data: {
                pageTitle: 'PointsTables'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/points-table/points-tables.html',
                    controller: 'PointsTableController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('points-table-detail', {
            parent: 'points-table',
            url: '/{id}',
            data: {
                pageTitle: 'PointsTable'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/points-table/points-table-detail.html',
                    controller: 'PointsTableDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PointsTable', function($stateParams, PointsTable) {
                    return PointsTable.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'points-table',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('points-table-detail.edit', {
            parent: 'points-table-detail',
            url: '/detail/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/points-table/points-table-dialog.html',
                    controller: 'PointsTableDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['PointsTable','$stateParams', function(PointsTable,$stateParams) {
                    return PointsTable.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('points-table.new', {
            parent: 'points-table',
            url: '/new',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/points-table/points-table-dialog.html',
                    controller: 'PointsTableDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        matches: null,
                        won: null,
                        lost: null,
                        tied: null,
                        noResult: null,
                        points: null,
                        netRR: null,
                        forScore: null,
                        forOvers: null,
                        againstScore: null,
                        againstOvers: null,
                        createdBy: null,
                        createdDate: null,
                        lastModifiedBy: null,
                        lastModifiedDate: null,
                        id: null
                    };
                }
            }

        })
        .state('points-table.edit', {
            parent: 'points-table',
            url: '/{id}/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/points-table/points-table-dialog.html',
                    controller: 'PointsTableDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['PointsTable','$stateParams', function(PointsTable,$stateParams) {
                    return PointsTable.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('points-table.delete', {
            parent: 'points-table',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/competition/points-table/points-table-delete-dialog.html',
                    controller: 'PointsTableDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PointsTable', function(PointsTable) {
                            return PointsTable.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('points-table', null, { reload: 'points-table' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
