(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('FixturesController', FixturesController);

    FixturesController.$inject = ['$scope', '$state', '$http', 'Fixtures', 'FixturesSearch', 'localStorageService', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants','Teams','Tournament'];

    function FixturesController ($scope, $state, $http, Fixtures, FixturesSearch, localStorageService, ParseLinks, AlertService, pagingParams, paginationConstants,Teams,Tournament) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 20;//paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        $scope.searchByTournament = searchByTournament;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search!==null?pagingParams.search.replace(/"/g,""):null;
        vm.currentSearch = pagingParams.search;
        vm.tournaments = Tournament.query();
        vm.loadFixtures = loadFixtures;
        vm.tournament = null; // = pagingParams.tournamentId;
      //  vm.tournamentId = pagingParams.tournamentId;
        vm.upcoming = null;

        loadAll();

        function loadAll () {
            $scope.items = vm.itemsPerPage;
            if (pagingParams.search) {
                FixturesSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    tournamentId: localStorageService.get('tournamentId')
                }, onSuccess, onError);
            } else {
                Fixtures.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    tournamentId: localStorageService.get('tournamentId'),
                    upcoming:vm.upcoming
                }, onSuccess, onError);
            }
            vm.tournament = localStorageService.get('tournament');
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.fixtures = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }


        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                upcoming:vm.upcoming,
                tournamentId: vm.tournament!=null?vm.tournament.id:null
            });
            if (vm.tournament !== null) {
                vm.tournament = localStorageService.get('tournament');
                vm.tournamentId = localStorageService.get('tournamentId');
            }
        }

        function searchByTournament(team) {
            $scope.items = vm.itemsPerPage;
            if (team) {
                localStorageService.set('tournamentId', team.id);
                localStorageService.set('tournament', team);
                pagingParams.tournamentId = vm.tournament.id;
                Fixtures.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    tournamentId: vm.tournament.id,
                    upcoming:vm.upcoming
                }, onSuccess, onError);
            } else {
                localStorageService.remove('tournamentId');
                localStorageService.remove('tournament');
                Fixtures.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    tournamentId: null,
                    upcoming:vm.upcoming
                }, onSuccess, onError);
            }
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'startDate';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.upcoming = function (upcoming) {
            $scope.items = vm.itemsPerPage;
            vm.upcoming = upcoming;
            Fixtures.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                tournamentId: vm.tournament!=null?vm.tournament.id:null,
                upcoming:upcoming
            }, onSuccess, onError);
        };

        vm.tournamentNameFilter = null;
        function loadFixtures(tournament, search) {
            var params = $.param({
                id: tournament.id,
                query: search,
                upcoming : null
            });
            var url = 'api/tournaments/fixturesByTournamentId?';
            // var fd = new FormData();
            // fd.append('year', $scope.year);
            // fd.append("user", $scope.user);
            $http.get(url + params)
                .success( function (data) {
                    vm.fixtures = data;

                    }
                )
                .error( function () {
                });
        }


        vm.selectedTournamentId = null;
        vm.selectedTournament = null;
        vm.autoCompleteOptions = {
            minimumChars: 1,
            activateOnFocus: true,
            data: function (searchText) {
                var params = $.param({
                    name: searchText
                });
                vm.selectedTournamentData =[];
                var url = 'api/tournaments/tournamentsByName?';
                return $http.get(url + params)
                    .then(function (response) {
                        // searchText = searchText.toUpperCase();

                        // ideally filtering should be done on the server
                        var tournaments = _.filter(response.data, function (tournament) {
                            vm.selectedTournamentId = tournament.id;
                            vm.selectedTournamentData = response.data;
                            return tournament.name.toLowerCase().startsWith(searchText) || tournament.name.startsWith(searchText);
                        });

                        return _.pluck(tournaments, 'name');
                    });
            },
            itemSelected: function (e) {
                angular.forEach(vm.selectedTournamentData, function (val) {
                    if (e.item === val.name) {
                        vm.selectedTournamentId = val.id;
                    }
                });
                console.log("selected tournament id " + vm.selectedTournamentId);
            }
        };

        vm.addTournament = addTournament;
        //  function addPlayer() {
        //      console.log("selected player id: " + vm.selectedPlayerId);
        //      console.log("selected team id: " + vm.teams.id);
        //  }

        function addTournament(selectedTou, fixtureId) {
            vm.selectedTournament = selectedTou;
            console.log("selected tournament id: " + vm.selectedTournamentId);
            console.log("selected fixture id: " + fixtureId);
            var url = 'api/tournaments/addTournament?';
            var params = $.param({
                fixtureId:fixtureId,
                tournamentId:vm.selectedTournamentId
            });
            $http.put(url + params)
                .success(function (data) {
                        clearSelection();
                    loadAll();
                    }
                )
                .error( function () {
                });
        }

        vm.removeTournament = removeTournament;
        function removeTournament(fixtureId) {
            var url = 'api/tournaments/removeTournament?';
            var params = $.param({
                fixtureId:fixtureId
               // tournamentId:vm.selectedTournamentId
            });
            $http.put(url + params)
                .success(function (data) {
                        clearSelection();
                    loadAll()
                    }
                )
                .error( function () {
                });

        }

        vm.clearSelection = clearSelection;
        function clearSelection() {
            vm.selectedTournament = null;
            vm.selectedTournamentId = null;
        }

        $scope.items = vm.itemsPerPage;
        $scope.scrollPage = function () {
            var limitReached = false;
            if (vm.fixtures!== undefined && vm.fixtures.length <= $scope.items - vm.itemsPerPage) {
                limitReached = true;
            }
            if (!limitReached) {
                $scope.items = $scope.items + vm.itemsPerPage;
                if (pagingParams.search) {
                    FixturesSearch.query({
                        query: pagingParams.search,
                        page: vm.page - 1,
                        size: $scope.items,
                        sort: sort(),
                        tournamentId: localStorageService.get('tournamentId')
                    }, onSuccess, onError);
                } else {
                    Fixtures.query({
                        page: vm.page - 1,
                        size: $scope.items,
                        sort: sort(),
                        tournamentId: localStorageService.get('tournamentId'),
                        upcoming:vm.upcoming
                    }, onSuccess, onError);
                }
            }

        }
    }
})();
