(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('CommentaryItemDeleteController',CommentaryItemDeleteController);

    CommentaryItemDeleteController.$inject = ['$uibModalInstance', 'entity', 'CommentaryItem'];

    function CommentaryItemDeleteController($uibModalInstance, entity, CommentaryItem) {
        var vm = this;

        vm.commentaryItem = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CommentaryItem.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
