(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('TeamsDeleteController',TeamsDeleteController);

    TeamsDeleteController.$inject = ['$uibModalInstance', 'entity', 'Teams'];

    function TeamsDeleteController($uibModalInstance, entity, Teams) {
        var vm = this;

        vm.teams = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Teams.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
