(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('TeamsDetailController', TeamsDetailController);

    TeamsDetailController.$inject = ['$scope', '$rootScope','$state', '$stateParams', '$http', 'previousState', 'entity', 'Teams', 'Groups', 'Players'];

    function TeamsDetailController($scope, $rootScope, $state, $stateParams, $http, previousState, entity, Teams, Groups, Players) {
        var vm = this;

        vm.teams = entity;
        vm.selectedPlayerId = null;
        vm.selectedPlayer = null;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('techdirectiveApp:teamsUpdate', function(event, result) {
            vm.teams = result;
        });
        $scope.$on('$destroy', unsubscribe);


        vm.autoCompleteOptions = {
            minimumChars: 1,
            activateOnFocus: true,
            data: function (searchText) {
                var params = $.param({
                    name: searchText
                });
                vm.selectedPlayerData =[];
                var url = 'api/players/playersByName?';
                return $http.get(url + params)
                    .then(function (response) {
                       // searchText = searchText.toUpperCase();

                        // ideally filtering should be done on the server
                        var players = _.filter(response.data, function (player) {
                            vm.selectedPlayerId = player.id;
                            vm.selectedPlayerData = response.data;
                            return player.name.toLowerCase().startsWith(searchText) || player.name.startsWith(searchText);
                        });

                        return _.pluck(players, 'name');
                    });
            },
            itemSelected: function (e) {
                angular.forEach(vm.selectedPlayerData, function (val) {
                    if (e.item === val.name) {
                        vm.selectedPlayerId = val.id;
                    }
                });
                console.log("selected player id " + vm.selectedPlayerId);
            }
        };

        vm.addPlayer = addPlayer;
      //  function addPlayer() {
      //      console.log("selected player id: " + vm.selectedPlayerId);
      //      console.log("selected team id: " + vm.teams.id);
      //  }

        function addPlayer() {
            console.log("selected player id: " + vm.selectedPlayerId);
            console.log("selected team id: " + vm.teams.id);
            var url = 'api/players/addPlayer?';
            var params = $.param({
                teamId:vm.teams.id,
                playerId:vm.selectedPlayerId
            });
            $http.put(url + params)
                .success(function (data) {
                        vm.teams = data;
                        clear();
                    }
                )
                .error( function () {
                });


          //  $state.go('teams-detail', {id:vm.teams.id}, { reload: 'teams-detail' });

            //  $state.go('tournament-detail', {id:vm.fixtures.tournament.id}, { reload: 'tournament-detail' });
        }

        vm.removePlayer = removePlayer;
        function removePlayer(id) {
            console.log("selected player id: " + id);
            console.log("selected team id: " + vm.teams.id);
            var url = 'api/players/removePlayer?';
            var params = $.param({
                teamId:vm.teams.id,
                playerId:id
            });
            $http.put(url + params)
                .success(function (data) {
                        vm.teams = data;
                        clear();
                    }
                )
                .error( function () {
                });


            //$state.go('teams-detail', {id:vm.teams.id}, { reload: 'teams-detail' });
        }

        vm.clear = clear;
        function clear() {
            vm.selectedPlayer = null;
            vm.selectedPlayerId = null;
        }
    }
})();
