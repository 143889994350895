/**
 * Created by Narotham on 05/11/2016.
 */

(function () {
    'use strict';

    angular
        .module('techdirectiveApp')
        .directive('ngSpinnerBar', ngSpinnerBar)
        .factory('settings', settings);

    ngSpinnerBar.$inject = ['$rootScope', '$state'];
    settings.$inject = ['$rootScope'];

    function ngSpinnerBar($rootScope, $state) {
        return {
            link: function (scope, element, attrs) {

            /*    $rootScope.$on('$viewContentLoaded', function() {
                    App.initComponents(); // init core components
                    //Layout.init(); //  Init entire layout(header, footer, sidebar, etc) on page load if the partials included in server side instead of loading with ng-include directive
                });*/

                // by defult hide the spinner bar
                element.addClass('hide'); // hide spinner bar by default

                // display the spinner bar whenever the route changes(the content part started loading)
                $rootScope.$on('$stateChangeStart', function () {
                    element.removeClass('hide'); // show spinner bar
                    Layout.closeMainMenu();
                });

                // hide the spinner bar on rounte change success(after the content loaded)
                $rootScope.$on('$stateChangeSuccess', function(event) {
                    element.addClass('hide'); // hide spinner bar
                    $('body').removeClass('page-on-load'); // remove page loading indicator
                    Layout.setAngularJsMainMenuActiveLink('match', null, event.currentScope.$state); // activate selected link in the sidebar menu

                    // auto scorll to page top
                    setTimeout(function () {
                        App.scrollTop(); // scroll to the top on content load
                    }, $rootScope.settings.layout.pageAutoScrollOnLoad);
                });

                // handle errors
                $rootScope.$on('$stateNotFound', function () {
                    element.addClass('hide'); // hide spinner bar
                });

                // handle errors
                $rootScope.$on('$stateChangeError', function () {
                    element.addClass('hide'); // hide spinner bar
                });
                $rootScope.$on('$includeContentLoaded', function() {
                    Layout.initHeader(); // init header
                    Layout.initFooter(); // init footer
                });
            }
        };
    }


    /* Setup global settings */
    function settings($rootScope) {
        // supported languages
        var settings = {
            layout: {
                pageSidebarClosed: false, // sidebar menu state
                pageContentWhite: true, // set page content layout
                pageBodySolid: false, // solid body color state
                pageAutoScrollOnLoad: 1000 // auto scroll to top on page load
            },
            assetsPath: '../../assets',
            globalPath: '../../assets/global',
            layoutPath: '../../assets/layouts/layout3'
        };

        $rootScope.settings = settings;

        return settings;
    }

})();

/* Init global settings and run the app */
angular
    .module('techdirectiveApp').run(["$rootScope", "settings", "$state", function($rootScope, settings, $state) {
    $rootScope.$settings = settings; // state to be accessed from view
}]);
