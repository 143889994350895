(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('SponsorsDialogController', SponsorsDialogController);

    SponsorsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$http', '$state', 'entity', 'Sponsors'];

    function SponsorsDialogController ($timeout, $scope, $stateParams, $http, $state, entity, Sponsors) {
        var vm = this;

        vm.sponsors = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        $scope.myFile = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('sponsors', null, { reload: 'sponsors' });
        }

        // begin upload user image //
        $scope.myFile = {};
        $scope.uploadPic = function(file) {

            var uploadUrl = 'api/sponsors/upload';
            var fd = new FormData();
            if (count_obj(file)) {
                fd.append('file', file);
            }
            fd.append('sponsors',angular.toJson(vm.sponsors));
            $http.post(uploadUrl, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
                .success( function (data) {
                        console.log('success');
                        onSaveSuccess(data);
                    }
                )
                .error( function () {
                    onSaveError();
                    console.log('error');
                });
        };

        function count_obj(obj){
            var i = 0;
            for(var key in obj){
                ++i;
            }
            return i;
        }

        function save () {
            vm.isSaving = true;
            if ($scope.myFile) {
                $scope.uploadPic($scope.myFile);
            }
            /*vm.isSaving = true;
            if (vm.sponsors.id !== null) {
                Sponsors.update(vm.sponsors, onSaveSuccess, onSaveError);
            } else {
                Sponsors.save(vm.sponsors, onSaveSuccess, onSaveError);
            }*/
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:sponsorsUpdate', result);
            $state.go('sponsors', null, { reload: 'sponsors' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
