(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('PlayersDetailController', PlayersDetailController);

    PlayersDetailController.$inject = ['$scope', '$rootScope', '$http', '$stateParams', 'previousState', 'entity', 'Players', 'Teams'];

    function PlayersDetailController($scope, $rootScope, $http, $stateParams, previousState, entity, Players, Teams) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.loadPlayerBattingStats = loadPlayerBattingStats;
        vm.loadPlayerFieldingStats = loadPlayerFieldingStats;
        vm.loadPlayerBowlingStats = loadPlayerBowlingStats;
        vm.loadTeamPlayers = loadTeamPlayers;
        vm.players = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('techdirectiveApp:playersUpdate', function(event, result) {
            vm.players = result;
        });
        $scope.$on('$destroy', unsubscribe);
        loadAll();
        function loadAll() {
            loadPlayerBattingStats();
            loadPlayerFieldingStats();
            loadPlayerBowlingStats();
            loadTeamPlayers();
        }
        vm.playerBattingStats = [];
        function loadPlayerBattingStats() {
            vm.playerBattingStats = [];
            var params = $.param({
                id:vm.players.id
            });
            var url = 'api/player-batting-stats?';
            $http.get(url + params)
                .success( function (data) {
                        vm.playerBattingStats = data;
                    }
                )
                .error( function () {
                });

        }

        vm.playerBowlingStats = [];
        function loadPlayerBowlingStats() {
            vm.playerBowlingStats = [];
            var params = $.param({
                id:vm.players.id
            });
            var url = 'api/player-bowling-stats?';
            $http.get(url + params)
                .success( function (data) {
                        vm.playerBowlingStats = data;
                    }
                )
                .error( function () {
                });

        }

        vm.playerFieldingStats = [];
        function loadPlayerFieldingStats() {
            vm.playerFieldingStats = [];
            var params = $.param({
                id:vm.players.id
            });
            var url = 'api/player-fielding-stats?';
            $http.get(url + params)
                .success( function (data) {
                        vm.playerFieldingStats = data;
                    }
                )
                .error( function () {
                });

        }

        function loadTeamPlayers() {
            if (vm.players!== null && vm.players.teams.length > 0) {
                vm.teamPlayers = [];
                var params = $.param({
                    id: vm.players.teams[0].id
                });
                var url = 'api/players/playersByTeamId?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.teamPlayers = data;
                        }
                    )
                    .error( function () {
                    });
            }
        }

        // Download PDF
        $scope.downloadPlayerStats = function() {
            html2canvas(document.getElementById('playerstatsind'), {
                onrendered: function(canvas) {
                    var filename = vm.players.teams[0].name+"_"+vm.players.name+".pdf";
                    var data = canvas.toDataURL();
                    var docDefinition = {
                        content: [{
                            image: data,
                            width: 780,
                            height:420
                        }],
                        // a string or { width: number, height: number }
                        pageSize: 'A4',

                        // by default we use portrait, you can change it to landscape if you wish
                        pageOrientation: 'landscape',

                        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                        pageMargins: [ 40, 60, 40, 60 ],
                    };
                    pdfMake.createPdf(docDefinition).download(filename);
                }
            });
        };
    }
})();
