(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('SponsorsDetailController', SponsorsDetailController);

    SponsorsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Sponsors'];

    function SponsorsDetailController($scope, $rootScope, $stateParams, previousState, entity, Sponsors) {
        var vm = this;

        vm.sponsors = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('techdirectiveApp:sponsorsUpdate', function(event, result) {
            vm.sponsors = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
