(function() {
    'use strict';

    angular.module('techdirectiveApp')
        .controller('CalendarModalController', CalendarModalController);

    CalendarModalController.$inject = ['$uibModalInstance', '$scope', 'selectedDate', '$state', '$stateParams', 'Calendar'];

    function CalendarModalController ($uibModalInstance, $scope, selectedDate, $state, $stateParams, Calendar) {
        var vm = this;

        vm.calendar = {};
        vm.cancel = cancel;
        vm.selectedDate = selectedDate;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.calendar. start= selectedDate.toDate();
        vm.calendar. end=selectedDate.toDate();
      //  vm.baseName = baseName;
      //  vm.subSystemName = subSystemName;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear () {
            $state.go('calendar', null, { reload: 'calendar' });
        }

        function save () {
            vm.isSaving = true;
            if (vm.calendar.id !== null) {
                Calendar.update(vm.calendar, onSaveSuccess, onSaveError);
            } else {
                Calendar.save(vm.calendar, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:calendarUpdate', result);
            $state.go('calendar', null, { reload: 'calendar' });
            vm.isSaving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
