(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$scope', '$http', '$state'];

    function ContactController ($scope, $http, $state) {
        var vm = this;

        vm.contact = {};
        vm.submit = submit;

        function submit () {
            vm.isSaving = true;

            var contactUrl = 'api/contacts';
            $http.post(contactUrl, vm.contact)
                .success( function (data) {
                        onSaveSuccess(data);
                    $state.go('contact');
                    }
                )
                .error( function () {
                    onSaveError();
                });

        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:contactUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

 }
})();
