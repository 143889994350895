(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('CalendarController', CalendarController);

    CalendarController.$inject = ['$scope', '$state', '$compile', 'Calendar', 'CalendarSearch','DTOptionsBuilder', 'DTColumnDefBuilder', '$uibModal'];

    function CalendarController ($scope, $state, $compile, Calendar, CalendarSearch,DTOptionsBuilder, DTColumnDefBuilder, $uibModal) {
        var vm = this;

        vm.calendars = [];
        vm.search = search;
        vm.loadAll = loadAll;
        $scope.events = [];
        vm.showCalendar = showCalendar;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('order', [1, 'desc'])
            .withDataProp(vm.calendars)
            .withOption('processing', true);

        loadAll();

        function loadAll() {
            Calendar.query(function(result) {
                vm.calendars = result;
                buildEvents(result);
                loadCalendar();
            });

        }


        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            CalendarSearch.query({query: vm.searchQuery}, function(result) {
                vm.calendars = result;
            });
        }

        function loadCalendar() {

            jQuery(document).ready(function() {
                $('#calendar').fullCalendar('destroy');
            });
           // $('#calendar').fullCalendar('destroy');

            var h = {};

            if ($('#calendar').parents(".portlet").width() <= 720) {
                $('#calendar').addClass("mobile");
                h = {
                    left: 'title, prev, next',
                    center: '',
                    right: 'today,month,agendaWeek,agendaDay'
                };
            } else {
                $('#calendar').removeClass("mobile");
                h = {
                    left: 'title',
                    center: '',
                    right: 'prev,next,today,month,agendaWeek,agendaDay'
                };
            }

            var $calendar = $('#calendar');
            $calendar.fullCalendar({
                header: h,
                editable: true,
                defaultView: 'month',
                allDayDefault: false,
                events: $scope.events,
                dayClick: function (date) {
                    // Create a string representing just the day/month/year of the date clicked
                   var  dateString = date._d.getDate() + date._d.getMonth() + date._d.getFullYear();
                    showCalendar(date);
                    // Grab the events for that day using a custom filter function
                },
                eventDrop: $scope.alertOnDrop,
                eventResize: $scope.alertOnResize,
                eventRender: $scope.eventRender
            });

        }

        /* alert on Drop */
        $scope.alertOnDrop = function(event, delta, revertFunc, jsEvent, ui, view){
            $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
            console.log(event.value);
        };
        /* alert on Resize */
        $scope.alertOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){
            $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
        };

        /* Render Tooltip */
        $scope.eventRender = function( event, element, view ) {
            element.attr({'tooltip': event.title,
                'tooltip-append-to-body': true});
            $compile(element)($scope);
        };

        function buildEvents(calendars) {
            angular.forEach(calendars, function (value, key) {
                var event = {
                    title: value.title,
                    start: new Date(value.start),
                    end: new Date(value.end),
                    allDay: value.allDay,
                    backgroundColor: App.getBrandColor(value.className),
                    url: 'calendar/' + value.id,
                    value: value
                };
                $scope.events.push(event);
            });
            return $scope.events;
        }

        function showCalendar(date) {
            data: {
                authorities: ['ROLE_USER']
            }
            $uibModal.open({
                templateUrl: 'app/calendar/calendar.modal.html',
                controller: 'CalendarModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    selectedDate: function() {
                        return date;
                    }
                }
            });
        }
    }

})();
