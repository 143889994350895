(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('TournamentDialogController', TournamentDialogController);

    TournamentDialogController.$inject = ['$timeout', '$scope', '$http', '$state', '$stateParams', 'entity', 'Tournament'];

    function TournamentDialogController ($timeout, $scope, $http, $state, $stateParams, entity, Tournament) {
        var vm = this;

        vm.tournament = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.currentUser = null;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('tournament', null, { reload: 'tournament' });
        }

        $scope.myFile = {};
        vm.deleteImage = null;
        function save (deleteImage) {
            var uploadUrl = 'api/tournaments';
            var fd = new FormData();
            if (count_obj($scope.myFile)) {
                fd.append('image', $scope.myFile);
            }

            if (deleteImage === true) {
                vm.tournament.image = null
            }
            fd.append('tournament', angular.toJson(vm.tournament));

            vm.isSaving = true;
            if (vm.tournament.id !== null) {
                $http.put(uploadUrl, fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                }) .success( function (data) {
                        console.log('success');
                        onSaveSuccess(data);
                    }
                )
                    .error( function () {
                        onSaveError();
                        console.log('error');
                    });
               // Tournament.update(vm.tournament, onSaveSuccess, onSaveError);
            } else {
                $http.post(uploadUrl, fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                })
                    .success( function (data) {
                            console.log('success');
                            onSaveSuccess(data);
                        }
                    )
                    .error( function () {
                        onSaveError();
                        console.log('error');
                    });
               // Tournament.save(vm.tournament, onSaveSuccess, onSaveError);
            }
        }

        function count_obj(obj){
            var i = 0;
            for(var key in obj){
                ++i;
            }
            return i;
        }
        vm.removeImage = removeImage;
        function removeImage(deleteImage){
            $scope.myFile = {};
            vm.deleteImage = deleteImage;
            vm.save(deleteImage);
        }
        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:tournamentUpdate', result);
            $state.go('tournament', null, { reload: 'tournament' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.created_date = false;
        vm.datePickerOpenStatus.last_modified_date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
