(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$http', 'ngMeta', 'Principal', 'LoginService', '$state', 'Calendar', 'WeatherIcons', 'Blog'];

    function HomeController ($scope, $http, ngMeta, Principal, LoginService, $state, Calendar, WeatherIcons, Blog) {

        $scope.$on('$includeContentLoaded', function() {
            Demo.init(); // init theme panel
        });

        var vm = this;

        vm.calendars = [];
        vm.loadAll = loadAll;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.current = null;
        $scope.weatherData = null;
        $scope.weather = [];
        $scope.iconClass;
        vm.blogs = Blog.query();


        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        loadAll();
        getAccount();

        /**
         * to load future calendar events in sorted order, very recent being on top
         */
        function loadAll() {
            Calendar.query(function(result) {
                var res = [];
                var today = new Date();
                angular.forEach(result, function (value, key) {
                    if ((new Date(value.start) >= today || new Date(value.end) >= today)) {
                        res.push(value);
                    }
                });


                // sort array by start date
                res.sort(function(a,b) {
                    return new Date(a.start).getTime() - new Date(b.start).getTime()
                });
                vm.calendars = res;
                $scope.current = vm.calendars[0];
            });

            // load weather data
            getWeather();

            // meta tags
            ngMeta.setTitle('Munich Spartans Cricket Club');
            ngMeta.setTag('keyword', 'Spartans Cricket Club');
            ngMeta.setTag('description', 'Munich Spartans Cricket Club (MSCC) was founded in 2016. The Club is based in Munich and is a non-profit organization. The club does not pursue primarily self-economical purposes. The prime goal is to develop the infrastructure of Cricket in Munich, to provide cricket for its members and to promote the game among the local people as well as to other interested people. The objective of bringing different people together is to enjoy the game with true sportsman spirit. The aim of the club is to plan, arrange play, conducting matches and participation in tournaments. The club does not discriminate on grounds of, age, ability, disability, gender, race, ethnicity, religious belief, sexuality or social/economic status and will not tolerate any form of sexual, racial or religious harassment. The club respects the rights, dignity and worth of every person and will treat everyone equally. Sports equity is about fairness in sport, equality of access, recognizing inequalities and taking steps to address them. It is about changing the culture and structure of sport to make sure it becomes equally accessible to everyone. The club is committed to everyone having the right to enjoy their sport in an environment free from threat of intimidation, harassment and abuse. All club members have a responsibility to oppose discriminatory behaviour and promote equality of opportunity. The club will deal with any incidence of discriminatory behaviour seriously, according to club disciplinary procedures.');
        }

        $scope.currentPage = function () {
            return vm.calendars.indexOf($scope.current);
        };
            $scope.next = function(){
                var i = $scope.getIndex(vm.calendars.indexOf($scope.current), 1);
                $scope.current = vm.calendars[i];
            };
            $scope.previous = function(){
                var i = $scope.getIndex(vm.calendars.indexOf($scope.current), -1);
                $scope.current = vm.calendars[i];
            };
            $scope.getIndex = function(currentIndex, shift){
                var len = vm.calendars.length;
                return (((currentIndex + shift) + len) % len)
            };




        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        // Blogs load more //
        vm.limit = 3;

        $scope.loadMore = function() {
            var increamented = vm.limit + 3;
            vm.limit = increamented > vm.blogs.length ? vm.blogs.length : increamented;
        };

        function getWeather() {
            var city = 'Munich';
            var url = 'api/weather';

            $http({
                url: url,
                method: "GET",
                params: {city: city}
            })
                .then(function successCallback(response) {
                $scope.weatherData = response.data;
                var singleData = response.data;
                $scope.weather = prepareWeather(singleData);
                $scope.iconClass = WeatherIcons[$scope.weather.icon];
                // this callback will be called asynchronously
                // when the response is available
            }, function errorCallback(response) {
                $scope.weatherData = null;
                // called asynchronously if an error occurs
                // or server returns response with an error status.
            });

        }

        // weather data to be displayed in UI
        function prepareWeather(weatherData) {
            return {
                time: new Date(), //moment.unix(weatherData.dt).format("DD MMM YYYY, HH:mm:ss"),
                city: weatherData.name,
                country: weatherData.sys.country,
                temp: weatherData.main.temp,
                temp_min: weatherData.main.temp_min,
                temp_max: weatherData.main.temp_max,
                humidity: weatherData.main.humidity,
                pressure: weatherData.main.pressure,
                icon: (angular.isDefined(WeatherIcons[weatherData.weather[0].icon])) ? weatherData.weather[0].icon : weatherData.weather[0].id,
                description: weatherData.weather[0].description
            }
        }
    }
})();
