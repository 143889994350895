(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('CalendarDetailController', CalendarDetailController);

    CalendarDetailController.$inject = ['$scope', 'ngMeta', '$http', '$state', '$interval', '$rootScope', '$stateParams', 'previousState', 'Principal', 'entity', 'Calendar', 'User'];

    function CalendarDetailController($scope, ngMeta, $http, $state, $interval, $rootScope, $stateParams, previousState, Principal, entity, Calendar, User) {
        var vm = this;

        vm.calendar = entity;
        vm.previousState = previousState.name;
        vm.currentUser = {};
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.getBgColor = getBgColor;

        var unsubscribe = $rootScope.$on('techdirectiveApp:calendarUpdate', function(event, result) {
            vm.calendar = result;
        });


        vm.load = load;
        vm.loadPie = loadPie;
        vm.vote = vote;
        vm.setActive = setActive;
        vm.reason = '';
        vm.saveReason = saveReason;
        vm.clearReason = clearReason;
        vm.availabilities = [];
        vm.availabilitiesYes = [];
        vm.availabilitiesYesUsers = [];
        vm.availabilitiesNo = [];
        vm.availabilitiesNoUsers = [];
        vm.colorPercentageYes = 0;
        vm.colorPercentageNo = 0;
        vm.currentAvailability = {};
        vm.imageCheckedYes = "../../content/images/unchecked@2x.png";
        vm.imageCheckedNo = "../../content/images/unchecked@2x.png";
        vm.currentDate = new Date();
        $scope.calendarStartDate = new Date(vm.calendar.start);

        Principal.identity().then(function(account) {
            if (vm.isAuthenticated()) {
                vm.currentUser = copyAccount(account);
                load();
                loadPie();
            }

        });
        var copyAccount = function (account) {
            return {
                firstName: account.firstName,
                lastName: account.lastName,
                login: account.login
            };
        };
        $scope.$on('authenticationSuccess', function() {

           // vm.user = User.query();
        });

        function setActive (av, isActivated) {
            av.available = isActivated;
            saveAvailability(av);
        }

        function saveAvailability(av) {
            var contactUrl = 'api/availabilities';
            $http.put(contactUrl, av)
                .success( function (data) {
                        //  onSaveSuccess(data);
                        $state.go('calendar-detail');
                        load();
                    }
                )
                .error( function () {
                    onSaveError();
                });
        }

        function saveReason() {
            //vm.currentAvailability.reason = reason;
            saveAvailability(vm.currentAvailability);
        }

        function clearReason() {
            vm.currentAvailability.reason = null;
        }
        function load() {
            var params = $.param({
                id: $stateParams.id
            });
            var contactUrl = 'api/availabilitiesByCalendar?';
            $http.get(contactUrl + params)
                .success( function (data) {
                    vm.availabilities = data;
                    vm.availabilitiesYes = [];
                    vm.availabilitiesNo = [];
                    vm.availabilitiesYesUsers = [];
                    vm.availabilitiesNoUsers = [];
                    for (var i=0; i<data.length; i++) {

                        if (data[i].available === true) {
                            vm.availabilitiesYes.push(data[i]);
                            vm.availabilitiesYesUsers.push(data[i].user);
                        } else {
                            vm.availabilitiesNo.push(data[i]);
                            vm.availabilitiesNoUsers.push(data[i].user);
                        }
                        if (data[i].user === vm.currentUser.login) {
                            vm.currentAvailability = data[i];

                        }
                        if(vm.currentAvailability.available===undefined) {
                            vm.imageCheckedYes = "../../content/images/unchecked@2x.png";
                            vm.imageCheckedNo = "../../content/images/unchecked@2x.png";
                        } else {
                            if (vm.currentAvailability.available===true) {
                                vm.imageCheckedYes = "../../content/images/checked@2x.png";
                                vm.imageCheckedNo = "../../content/images/unchecked@2x.png";
                            } else {
                                vm.imageCheckedNo = "../../content/images/checked@2x.png";
                                vm.imageCheckedYes = "../../content/images/unchecked@2x.png";
                            }
                        }
                        vm.colorPercentageYes = (vm.availabilitiesYes.length/data.length)*100;
                        vm.colorPercentageNo = (vm.availabilitiesNo.length/data.length)*100;

                    }
                    ngMeta.setTitle('Calendar | ' + vm.calendar.title);
                    ngMeta.setTag('keyword', vm.calendar.title);
                    ngMeta.setTag('description', vm.calendar.title);
                        $state.go('calendar-detail');
                    loadPie();
                    }
                )

        }

        function vote (value) {
            vm.isvoted = true;
            var params = $.param({
                id: $stateParams.id,
                key: value
            });

            if (value === true) {
                vm.imageCheckedYes = "../../content/images/checked@2x.png";
                vm.imageCheckedNo = "../../content/images/unchecked@2x.png";
            } else {
                vm.imageCheckedNo = "../../content/images/checked@2x.png";
                vm.imageCheckedYes = "../../content/images/unchecked@2x.png";
            }
            var contactUrl = 'api/calendarsubmit?';
            $http.put(contactUrl + params)
                .success( function (data) {
                      //  onSaveSuccess(data);
                        $state.go('calendar-detail');
                    load();
                    }
                )
                .error( function () {
                    onSaveError();
                });

            if (value === true) {
                vm.currentAvailability.reason = null;
                saveAvailability(vm.currentAvailability);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:calendarsubmited', result);
            vm.isvoted = true;
        }

        function onSaveError () {
            vm.isvoted = false;
        }

        function loadPie() {

            // based on prepared DOM, initialize echarts instance
           // var myChart = echarts.init(document.getElementById('main'), 'vintage');
            var myChart = echarts.init(document.getElementById('main'), 'macarons');

            // specify chart configuration item and data
            var option = {
                title: {
                    text: vm.calendar.title,
                   // subtext: 'Availability',
                    x:'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: "{a} <br/>{b} : {c} ({d}%)"
                },
                legend: {
                    orient : 'vertical',
                    x : 'left',
                    data:['Yes','No'],
                    formatter:function(name){
                        var oa = option.series[0].data;
                        var num = oa[0].value + oa[1].value;
                        for(var i = 0; i < option.series[0].data.length; i++){
                            if(name===oa[i].name){
                                return name + '     ' + oa[i].value + '     ' + (oa[i].value/num * 100).toFixed(2) + '%';
                            }
                        }
                    }
                },
                calculable : false,
                series: [{
                    name:'Availability percentage',
                    type:'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data:[
                        {value:vm.availabilitiesYesUsers.length, name:'Yes'},
                        {value:vm.availabilitiesNoUsers.length, name:'No'}

                    ],
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        },
                        normal: {
                            label:{
                                show: true,
//	                            position:'inside',
                                formatter: '{b} : {c} ({d}%)'
                            }
                        },
                        labelLine :{show:true}
                    }
                }],
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                }

                /*,
                color : [
                    '#2ec7c9','#b6a2de','#5ab1ef','#ffb980','#d87a80',
                    '#8d98b3','#e5cf0d','#97b552','#95706d','#dc69aa',
                    '#07a2a4','#9a7fd1','#588dd5','#f5994e','#c05050',
                    '#59678c','#c9ab00','#7eb00a','#6f5553','#c14089'
                ]*/
            };

            // use configuration item and data specified to show chart
            myChart.setOption(option);

        }

        $scope.$on('$destroy', unsubscribe);
        function getBgColor(value) {
            // return { 'background-color': " + value + "}
            return App.getBrandColor(value)
        }
    }
})();
