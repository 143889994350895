(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('teams', {
            parent: 'cmp',
            url: '/teams?page&sort&search',
            data: {
                pageTitle: 'Teams'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/teams/teams.html',
                    controller: 'TeamsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('teams-detail', {
            parent: 'teams',
            url: '/{id}',
            data: {
                pageTitle: 'Teams'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/teams/teams-detail.html',
                    controller: 'TeamsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Teams', function($stateParams, Teams) {
                    return Teams.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'teams',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('teams-detail.edit', {
            parent: 'teams-detail',
            url: '/detail/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/teams/teams-dialog.html',
                    controller: 'TeamsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Teams', '$stateParams', function(Teams, $stateParams) {
                    return Teams.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('teams.new', {
            parent: 'teams',
            url: '/new',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/teams/teams-dialog.html',
                    controller: 'TeamsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        coach: null,
                        captain: null,
                        ranking: null,
                        createdBy: null,
                        createdDate: null,
                        lastModifiedBy: null,
                        lastModifiedDate: null,
                        teamLogo: null,
                        clubTeam: false,
                        active: true,
                        id: null
                    };
                }
            }
        })
        .state('teams.edit', {
            parent: 'teams',
            url: '/{id}/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/teams/teams-dialog.html',
                    controller: 'TeamsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Teams', '$stateParams', function(Teams, $stateParams) {
                    return Teams.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('teams.delete', {
            parent: 'teams',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/competition/teams/teams-delete-dialog.html',
                    controller: 'TeamsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Teams', function(Teams) {
                            return Teams.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('teams', null, { reload: 'teams' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
