(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('TournamentDetailController', TournamentDetailController);

    TournamentDetailController.$inject = ['$scope', '$http', 'ngMeta', '$window', '$sce', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Tournament','Teams'];

    function TournamentDetailController($scope, $http, ngMeta, $window, $sce, $rootScope, $state, $stateParams, previousState, entity, Tournament,Teams) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.tournament = entity;
        vm.previousState = previousState.name;
        vm.fixtures = [];
        vm.groups = [];
      //  vm.teams = Teams.query();
        vm.battingStats = [];
        vm.bowlingStats = [];
        vm.fieldingStats = [];
        vm.upcoming = null;
        vm.pdfcontent = null;
        vm.showpdf = false;
        vm.pdfData = null;

        vm.pointsTable={};
        loadAll();
        vm.loadFixtures = loadFixtures;
        vm.loadGroups = loadGroups;
        vm.tournamentBattingStats = tournamentBattingStats;
        vm.tournamentBowlingStats = tournamentBowlingStats;
        vm.tournamentFieldingStats = tournamentFieldingStats;
        vm.loadTournamentTeams = loadTournamentTeams;
        vm.initLoad = initLoad;
        function loadAll() {
            loadFixtures();
           // loadGroups();
            loadPointsTable();
            initLoad();
            tournamentBattingStats();
            tournamentBowlingStats();
            tournamentFieldingStats();
            loadTournamentTeams();
        }

        vm.savePointsTable = savePointsTable;
        vm.loadPointsTable = loadPointsTable;

        vm.teamNameFilter = null;
        vm.clear = clear;
        function clear () {
            vm.teamNameFilter = null;
            loadFixtures();
        }

        $rootScope.$on('techdirectiveApp:loadFixtures', function () {
            loadFixtures();
        });

        function loadFixtures() {
            var params = $.param({
                id: vm.tournament.id,
                query: vm.teamNameFilter,
                upcoming : vm.upcoming
            });
            var url = 'api/tournaments/fixturesByTournamentId?';
            // var fd = new FormData();
            // fd.append('year', $scope.year);
            // fd.append("user", $scope.user);
            $http.get(url + params)
                .success( function (data) {
                    vm.fixtures = data;

                    }
                )
                .error( function () {
                });
            ngMeta.setTitle('Tournament | ' + vm.tournament.name);
            ngMeta.setTag('keyword', vm.tournament.name);
            ngMeta.setTag('description', vm.tournament.name);


        }

        $scope.upcoming = function (upcoming) {
            vm.upcoming = upcoming;
            loadFixtures(vm.upcoming)
        };

        function loadGroups() {
            vm.groups = [];
            var params = $.param({
                id: vm.tournament.id
            });
            var url = 'api/tournaments/groupsByTrounamentId?';
            $http.get(url + params)
                .success( function (data) {
                        vm.groups = data;

                    }
                )
                .error( function () {
                });
        }

        function initLoad() {
            vm.battingStatsFilterOptions = [
                {value:'runsScored', name:'Highest Scores In Tournament'},
                {value:'maxScore', name:'Highest Scores In Match'},
                {value:'avg', name:'Highest Average'},
                {value:'strikeRate', name:'Highest Strike Rate'},
                {value:'matches', name:'Highest Innings'},
                {value:'sixes', name:'Highest Sixes In Tournament'},
                {value:'fours', name:'Highest Fours In Tournament'},
                {value:'no30s', name:'Highest 30s In Tournament'}
                ,{value:'no50s', name:'Highest 50s In Tournament'}
            ];
            vm.bowlingStatsFilterOptions = [
                {value:'wickets', name:'Highest Wickets In Tournament'},
                {value:'econ', name:'Best Economy Bowling'},
                {value:'avg', name:'Best Average Bowling'},
                {value:'strikeRate', name:'Best Strike Rates'},
                {value:'maxWickets', name:'Best Bowling Figures'},
                {value:'maidens', name:'Highest Maidens In Tournament'},
                {value:'matches', name:'Highest Innings'}
            ];
            vm.fieldingStatsFilterOptions = [
                {value:'catches', name:'Most Catches'},
                {value:'stumpings', name:'Most Stumpings'},
                {value:'runOuts', name:'Most Runouts'},
                {value:'matches', name:'Highest Innings'}
            ];
        }

        vm.battingStatsFilterSize = [20, 40, 60,80, 100];
        vm.bowlingStatsFilterSize = [20, 40, 60,80, 100];
        vm.fieldingStatsFilterSize = [20, 40, 60,80, 100];


        vm.battingStatsFilterCount=20;
        vm.battingSelectedFilterOption = vm.battingStatsFilterOptions[0];
        function tournamentBattingStats() {
            vm.battingStats = [];
            var filterBy = vm.battingSelectedFilterOption!=null?vm.battingSelectedFilterOption.value:"";
            var fSize = vm.battingStatsFilterCount===undefined?20:vm.battingStatsFilterCount;

            var params = $.param({
                id: vm.tournament.id,
                filterBy: filterBy,
                fSize: fSize
            });
            var url = 'api/tournaments/tournamentBattingStats?';
            $http.get(url + params)
                .success( function (data) {
                        vm.battingStats = data;

                    }
                )
                .error( function () {
                });
        }


        vm.bowlingStatsFilterCount=20;
        vm.bowlingSelectedFilterOption = vm.bowlingStatsFilterOptions[0];
        function tournamentBowlingStats() {
            vm.bowlingStats = [];
            var filterBy = vm.bowlingSelectedFilterOption!=null?vm.bowlingSelectedFilterOption.value:"";
            var fSize = vm.bowlingStatsFilterCount===undefined?20:vm.bowlingStatsFilterCount;
            var params = $.param({
                id: vm.tournament.id,
                filterBy: filterBy,
                fSize: fSize
            });
            var url = 'api/tournaments/tournamentBowlingStats?';
            $http.get(url + params)
                .success( function (data) {
                        vm.bowlingStats = data;

                    }
                )
                .error( function () {
                });
        }

        vm.fieldingStatsFilterCount=20;
        vm.fieldingSelectedFilterOption = vm.fieldingStatsFilterOptions[0];
        function tournamentFieldingStats() {
            vm.fieldingStats = [];
            var filterBy = vm.fieldingSelectedFilterOption!=null?vm.fieldingSelectedFilterOption.value:"";
            var fSize = vm.fieldingStatsFilterCount==undefined?20:vm.fieldingStatsFilterCount;
            var params = $.param({
                id: vm.tournament.id,
                filterBy: filterBy,
                fSize: fSize
            });
            var url = 'api/tournaments/tournamentFieldingStats?';
            $http.get(url + params)
                .success( function (data) {
                        vm.fieldingStats = data;

                    }
                )
                .error( function () {
                });
        }

        function savePointsTable() {
            var url = 'api/points-tables';
            // value.teams = vm.fixtures.firstTeam;
            $http.put(url, vm.pointsTable)
                .success( function (data) {
                        console.log('success');
                        onSaveSuccess(data);
                    }
                )
                .error( function () {
                    onSaveError();
                    console.log('error');
                });


        }

        function loadPointsTable() {
            vm.pointsTableList = [];
            var params = $.param({
                tournamentId:vm.tournament.id
            });
            var url = 'api/points-tables/tournamentId?';
            $http.get(url + params)
                .success( function (data) {
                    angular.forEach(data, function (value, key) {
                        vm.pointsTableList.push(value);
                    });
                    }
                )
                .error( function () {
                });

        }

        // tournament teams filter
        function loadTournamentTeams() {
            vm.tournamentTeams = [];
            var params = $.param({
                id: vm.tournament.id
            });
            var url = 'api/tournaments/findTeamsByFixturesAndTournamentId?';
            $http.get(url + params)
                .success( function (data) {
                        vm.tournamentTeams = data;
                    }
                )
                .error( function () {
                });
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:pointsTableUpdate', result);
            $state.go('tournament-detail', null, null);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        var unsubscribe = $rootScope.$on('techdirectiveApp:tournamentUpdate', function(event, result) {
            vm.tournament = result;
        });
        $scope.$on('$destroy', unsubscribe);


        // PDF

        $scope.downloadFixtures = function() {
            var params = $.param({
                id: vm.tournament.id,
                query: vm.teamNameFilter,
                upcoming : vm.upcoming
            });
            var pdfurl = 'api/tournaments/generateTournamentPdf?';
            $http.get(pdfurl + params, {responseType:'arraybuffer'})
                .success( function (data, headers) {
                        // var file = new Blob([data], {type: 'application/pdf'});
                        // var fileURL = URL.createObjectURL(file);
                        //  $scope.content = $sce.trustAsResourceUrl(fileURL);
                        //  window.open(fileURL);
                        vm.pdfData = data;
                    if (vm.pdfData && vm.pdfData.byteLength > 0) {
                        var fileName = vm.teamNameFilter != null ? vm.tournament.name + '_' + vm.teamNameFilter : vm.tournament.name;
                        var file = new Blob([ vm.pdfData ], {
                            type : 'application/pdf'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a         = document.createElement('a');
                        a.href        = fileURL;
                        a.target      = '_blank';
                        a.download    = fileName+'.pdf';
                        document.body.appendChild(a);
                        a.click();
                    }
                    }
                )
                .error( function () {
                });

        };
        $scope.downloadBattingStats = function() {
            html2canvas(document.getElementById('tbattingstats'), {
                onrendered: function(canvas) {
                    var filename = vm.tournament.name+"-batting-stats.pdf";
                    var data = canvas.toDataURL();
                    var docDefinition = {
                        content: [{
                            image: data,
                            width: 500,
                            height:750
                        }]
                    };
                    pdfMake.createPdf(docDefinition).download(filename);
                }
            });
        };
        $scope.downloadBowlingStats = function() {
            html2canvas(document.getElementById('tbowlingstats'), {
                onrendered: function(canvas) {
                    var filename = vm.tournament.name+"-bowling-stats.pdf";
                    var data = canvas.toDataURL();
                    var docDefinition = {
                        content: [{
                            image: data,
                            width: 500,
                            height:750
                        }]
                    };
                    pdfMake.createPdf(docDefinition).download(filename);
                }
            });
        };
        $scope.downloadFieldingStats = function() {
            html2canvas(document.getElementById('tfieldingstats'), {
                onrendered: function(canvas) {
                    var filename = vm.tournament.name+"-fielding-stats.pdf";
                    var data = canvas.toDataURL();
                    var docDefinition = {
                        content: [{
                            image: data,
                            width: 500,
                            height:750
                        }]
                    };
                    pdfMake.createPdf(docDefinition).download(filename);
                }
            });
        };

        $scope.downloadPointsTable = function() {
            html2canvas(document.getElementById('tptable'), {
                onrendered: function(canvas) {
                    var filename = vm.tournament.name+"-pointstable.pdf";
                    var data = canvas.toDataURL();
                    var docDefinition = {
                        content: [{
                            image: data,
                            width: 760,
                            height:500
                        }],
                        // a string or { width: number, height: number }
                        pageSize: 'A4',

                        // by default we use portrait, you can change it to landscape if you wish
                        pageOrientation: 'landscape',

                        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                        pageMargins: [ 40, 60, 40, 60 ],
                    };
                    pdfMake.createPdf(docDefinition).download(filename);
                }
            });
        };

    }
})();
