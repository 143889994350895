(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .service('multipartForm', ['$http', function($http) {
            this.post = function(uploadUrl, data) {
                var fd = new FormData();
                for (var key in data) {
                    fd.append(key, data[key]);
                }
                $http.post(uploadUrl, fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                })
                    .success( function () {
                        console.log('success');
                        }
                    ).error( function () {
                    console.log('error');
                })
            }

        }])
})();
