(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('FixturesDialogController', FixturesDialogController);

    FixturesDialogController.$inject = ['$timeout', '$scope', '$http', '$stateParams', '$state', 'entity', 'Fixtures', 'Tournament','Teams'];

    function FixturesDialogController ($timeout, $scope, $http, $stateParams, $state, entity, Fixtures, Tournament,Teams) {
        var vm = this;

        vm.fixtures = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.tournaments = Tournament.query();
        vm.teams = [];
        vm.loadPlayersOfTheMatch = loadPlayersOfTheMatch;
        loadPlayersOfTheMatch();
        vm.loadActiveTeams = loadActiveTeams;
        loadActiveTeams();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('fixtures', null, { reload: 'fixtures' });
        }

        function save () {
            vm.isSaving = true;
            if (vm.fixtures.id !== null) {
                Fixtures.update(vm.fixtures, onSaveSuccess, onSaveError);
            } else {
                Fixtures.save(vm.fixtures, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('techdirectiveApp:fixturesUpdate', result);
            $state.go('fixtures', null, { reload: 'fixtures' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.matchDate = false;
        vm.datePickerOpenStatus.matchTime = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadPlayersOfTheMatch() {
            if (vm.fixtures!==null && vm.fixtures.id != null) {
                vm.playersList = [];
                var params = $.param({
                    fixtureId:vm.fixtures.id
                });
                var url = 'api/fixtures/getPlayersOfTheMatch?';
                $http.get(url + params)
                    .success( function (data) {
                            vm.playersList = data;
                        }
                    )
                    .error( function () {
                    });
            }

        }

        function loadActiveTeams() {
            var url = 'api/teams/active';
            $http.get(url)
                .success(function (data) {
                        vm.teams = data;
                    }
                )
                .error( function () {
                });


        }
    }
})();
