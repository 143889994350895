(function () {
    'use strict';

    angular
        .module('techdirectiveApp')
        .factory('ElasticsearchReindex', ElasticsearchReindex);

    ElasticsearchReindex.$inject = ['$resource'];

    function ElasticsearchReindex($resource) {
        return $resource('api/elasticsearch/index', {}, {
            'reindex': {method: 'POST'}
        });
    }
})();
