(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('AvmanagementController', AvmanagementController);

    AvmanagementController.$inject = ['$scope', '$http', '$state'];

    function AvmanagementController ($scope, $http, $state) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadBarChat = loadBarChart;
        vm.dataName = [];
        vm.dataValue = [];
        vm.requiredValue = [];
        $scope.user = "admin";
        $scope.year = new Date().getFullYear().toString() ;
        loadAll();
        loadBarChart();
        vm.onOptionChange = onOptionChange;
        function loadAll() {
            onOptionChange();
        }
        function onOptionChange() {
            vm.dataName =  [];
            vm.dataValue = [];
            vm.requiredValue = [];
            vm.userAvailability = [];
            var params = $.param({
                year: $scope.year
            });
            var url = 'api/users/availability?';
            // var fd = new FormData();
            // fd.append('year', $scope.year);
            // fd.append("user", $scope.user);
            $http.get(url + params)
                .success( function (data) {
                        vm.userAvailability = data;
                        var fbHeight = 100;
                        angular.forEach(data.slice().reverse(), function (data) {
                            vm.dataName.push(data.user);
                            vm.dataValue.push(data.availabilityPercent);
                            vm.requiredValue.push(50);
                            fbHeight = fbHeight + 100;
                        });
                    loadBarChart();
                    vm.availabilityPercentH = fbHeight;
                    }
                )
                .error( function () {
                });

        }

        // year select box 3 years past + current year + 3 years future
        $scope.currentYear = new Date().getFullYear();
        $scope.startYear = $scope.currentYear - 3;
        $scope.endYear = $scope.currentYear + 3;
        // INIT YEARS
        $scope.selects = {
            years: function(){
                var yearsList = [];
                for( var i = $scope.currentYear - 1; i > $scope.startYear ; i--){
                    yearsList.push( i.toString() );
                }
                for( var j = $scope.currentYear; j < $scope.endYear ; j++){
                    yearsList.push( j.toString() );
                }
                return yearsList.sort();
            }
        };

        // Bar Chart
        function loadBarChart() {

            // based on prepared DOM, initialize echarts instance
            var myChart = echarts.init(document.getElementById('main'), 'macarons');

            // specify chart configuration item and data
            var option = {
                title : {
                    text: 'Availability percentage',
                    subtext: 'Percentage by user'
                },
                tooltip : {
                    trigger: 'axis',
                    formatter: "<bold>{b}</bold>  <br/>{a}: {c} %",
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        dataView : {show: true, readOnly: false, title:"Data view", lang:['Data view','Ok','Refresh']},
                        magicType: {show: true, type: ['line', 'bar'], title:{line:"line",bar:"bar"}},
                        restore : {show: true, title:"Restore"},
                        saveAsImage : {show: true, title:"Save"}
                    }
                },
                legend: {
                    data:['percentage','required']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                calculable : false,
                yAxis: {
                    type : 'category',
                    data :  vm.dataName,
                    axisLabel:{
                        interval:0,
                        rotate:30,
                        margin: 30,
                        textStyle:{
                            align: 'center',
                            color: '#000',
                            fontSize: 14
                        }
                    }
                },
                xAxis: {
                    type : 'value',
                    axisLabel:{
                        formatter:'{value} %',
                        textStyle:{
                            align: 'center',
                            color: '#000',
                            fontSize: 14
                        }
                    }
                },
                series : [
                    {
                        name:'percentage',
                        type:'bar',
                        data: vm.dataValue,
                        label: {
                            normal: {
                                show: true,
                                position: 'insideRight'
                            }
                        }
                    },
                    {
                        name:'required',
                        type:'bar',
                        data: vm.requiredValue,
                        markLine : {
                            data : [
                                {type : 'max', name: 'min'}
                            ]
                        },
                        label: {
                            normal: {
                                show: true,
                                position: 'insideRight'
                            }
                        }

                    }
                ]

            };

            // use configuration item and data specified to show chart
            myChart.setOption(option);
            myChart.resize();
            return myChart;

        }

        $scope.tab = 1;
        $scope.setTab = function(newTab){
            $scope.tab = newTab;
            if (newTab === 2) {
                loadBarChart();
                loadAll();
            } else {
                $state.go('avmanagement', null, { reload: 'avmanagement' });
            }
        };
        $scope.isSet = function(tabNum){
            return $scope.tab === tabNum;
        };

    }

})();
