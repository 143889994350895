(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .controller('AlbumController', AlbumController);

    AlbumController.$inject = ['$scope', '$http', '$state', 'Album', 'AlbumSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function AlbumController ($scope, $http, $state, Album, AlbumSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 50;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.loadActiveAlbum = loadActiveAlbum;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.currentTab = '1';
        vm.photos = {};
        vm.activeTabAlbum = {};
        vm.loadActiveTab = loadActiveTab;
        vm.loadActiveTabIndex = loadActiveTabIndex;
        $scope.activeTabIndex = 0;
        $scope.albumList = Album.query();

        function loadActiveAlbum() {
            vm.loadActiveTab();
            vm.loadActiveTabIndex();
        }


        function loadActiveTab() {
            var url = 'api/findActiveAlbumId';
            $http.get(url, {
            })
                .success( function (data) {
                        console.log('success');
                        vm.activeTabAlbum = data;
                        $scope.getPhotos(vm.activeTabAlbum.id);
                        vm.currentTab = vm.activeTabAlbum.name;
                        $scope.isActiveTab(vm.activeTabAlbum.id);
                    }
                )
                .error( function () {
                    console.log('error');
                });
        }
        function loadActiveTabIndex() {
            var url = 'api/activeTabIndex';
            $http.get(url, {
            })
                .success( function (data) {
                    $scope.activeTabIndex = data;

                    }
                )
                .error( function () {
                    console.log('error');
                });
        }

        $scope.getPhotos = function (id) {
            var url = 'api/photosByAlbumId';
            $http.get(url, {
                params: { id: id }
            })
                .success( function (data) {
                        console.log('success');
                        vm.photos = data;
                    }
                )
                .error( function () {
                    console.log('error');
                });
        };

        $scope.onClickTab = function (album) {
            vm.currentTab = album.name;
            $scope.getPhotos(album.id);
        };

        $scope.isActiveTab = function(name) {
            return name === vm.currentTab;
        };

        loadAll();

        function loadAll () {
            loadActiveAlbum();
            if (pagingParams.search) {
                AlbumSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Album.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.albums = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
