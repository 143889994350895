(function() {
    'use strict';

    angular
        .module('techdirectiveApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('groups', {
            parent: 'cmp',
            url: '/groups?page&sort&search',
            data: {
                pageTitle: 'Groups'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/groups/groups.html',
                    controller: 'GroupsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('groups-detail', {
            parent: 'groups',
            url: '/{id}',
            data: {
                pageTitle: 'Groups'
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/groups/groups-detail.html',
                    controller: 'GroupsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Groups', function($stateParams, Groups) {
                    return Groups.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'groups',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('groups-detail.edit', {
            parent: 'groups-detail',
            url: '/detail/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/groups/groups-dialog.html',
                    controller: 'GroupsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Groups','$stateParams', function(Groups,$stateParams) {
                    return Groups.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('groups.new', {
            parent: 'groups',
            url: '/new',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/groups/groups-dialog.html',
                    controller: 'GroupsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        createdBy: null,
                        createdDate: null,
                        lastModifiedBy: null,
                        lastModifiedDate: null,
                        id: null
                    };
                }
            }

        })
        .state('groups.edit', {
            parent: 'groups',
            url: '/{id}/edit',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/competition/groups/groups-dialog.html',
                    controller: 'GroupsDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Groups','$stateParams', function(Groups,$stateParams) {
                    return Groups.get({id : $stateParams.id}).$promise;
                }]
            }

        })
        .state('groups.delete', {
            parent: 'groups',
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/competition/groups/groups-delete-dialog.html',
                    controller: 'GroupsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Groups', function(Groups) {
                            return Groups.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('groups', null, { reload: 'groups' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
